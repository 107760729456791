import isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';

type UseLocalStorageOptions = {
    defaultValue?: string;
    syncData?: boolean;
};

export function useLocalStorage(
    key: LocalStorageKey,
    opts?: UseLocalStorageOptions,
): [string, (value: string) => void] {
    const { defaultValue = '', syncData = false } = opts || {};
    const storedValue = localStorage.getItem(key);
    const [value, setValue] = useState(storedValue ?? defaultValue);

    const setStoredValue = (newValue: string): void => {
        setValue(newValue);

        if (!isUndefined(window)) {
            localStorage.setItem(key, newValue);
            window.dispatchEvent(new StorageEvent('storage', { newValue, key }));
        }
    };

    useEffect(() => {
        if (!syncData || isUndefined(window)) {
            return;
        }

        const handleStorageChange = (e: StorageEvent) => {
            if (!e.newValue || value === e.newValue || key !== e.key) {
                return;
            }
            setValue(e.newValue);
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, [syncData, key, value]);

    return [value, setStoredValue];
}

export const enum LocalStorageKey {
    THEME = 'theme',
    LANGUAGE = 'language',
    LAST_ROUTE = 'lastRoute',
}
