import { relationEntityTypeDetailsMapping } from '@shared-lib/entity-type/entity-type-relations.mapping.ts';
import httpClient from '@shared/http/http.service.ts';
import { isNil } from 'lodash';
import { AttachmentListWithBulkApiService } from '../../crud/attachment-list-with-bulk/attachment-list-with-bulk-api.service.ts';
import { type CreateDocument, type Document, type DocumentsQuery, type UpdateDocument } from './documents.types.ts';

const PREFIX = 'documents';

export class DocumentsService extends AttachmentListWithBulkApiService<
    Document,
    DocumentsQuery,
    CreateDocument,
    UpdateDocument
> {
    public constructor() {
        super('documents');
    }

    public uploadDocument(createDocument: CreateDocument): Promise<Document> {
        const formData = new FormData();
        const { entityId, entityType, ...document } = createDocument;
        // TODO(xakeppok): i think we should pass just entityType and handle this on server side
        const [relation] =
            Object.entries(relationEntityTypeDetailsMapping).find(([, { entityType: rt }]) => rt === entityType) || [];
        if (relation) {
            formData.append(relation, `${entityId}`);
        }

        Object.entries(document).forEach(
            ([key, value]: [key: string, value: string[] | string | boolean | Blob | null]) => {
                if (isNil(value)) {
                    return;
                }
                const stringValue = typeof value === 'boolean' || Array.isArray(value) ? JSON.stringify(value) : value;
                formData.append(key, stringValue);
            },
        );
        return httpClient.post(`${PREFIX}`, formData);
    }
}

const documentsService = new DocumentsService();
export default documentsService;
