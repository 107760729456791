import type { Country } from '../types/country.type';

export const enum FileSizes {
    SIZE_5MB = 5242880,
    SIZE_1MB = 1048576,
    SIZE_5GB = 5368709120,
}

export const countries: Country[] = [
    {
        name: 'Andorra',
        countryCode: 'AD',
        translateKey: 'ANDORRA',
        flag: '🇦🇩',
        dial_code: '+376',
        mask: '000-000',
    },
    {
        name: 'Albania',
        countryCode: 'AL',
        translateKey: 'ALBANIA',
        flag: '🇦🇱',
        dial_code: '+355',
        mask: '(000)000-000',
    },
    {
        name: 'Argentina',
        countryCode: 'AR',
        translateKey: 'ARGENTINA',
        flag: '🇦🇷',
        dial_code: '+54',
        mask: '(000)000-0000',
    },
    {
        name: 'Austria',
        countryCode: 'AT',
        translateKey: 'AUSTRIA',
        flag: '🇦🇹',
        dial_code: '+43',
        mask: '(000)000-0000',
    },
    {
        name: 'Australia',
        countryCode: 'AU',
        translateKey: 'AUSTRALIA',
        flag: '🇦🇺',
        dial_code: '+61',
        mask: '0-0000-0000',
    },
    {
        name: 'Åland Islands',
        countryCode: 'AX',
        translateKey: 'ALAND_ISLANDS',
        flag: '🇦🇽',
        dial_code: '+358',
        mask: '(000)000-00-00',
    },
    {
        name: 'Bosnia and Herzegovina',
        countryCode: 'BA',
        translateKey: 'BOSNIA_AND_HERZEGOVINA',
        flag: '🇧🇦',
        dial_code: '+387',
        mask: '(00) 000-000',
    },
    {
        name: 'Barbados',
        countryCode: 'BB',
        translateKey: 'BARBADOS',
        flag: '🇧🇧',
        dial_code: '+1246',
        mask: '000-0000',
    },
    {
        name: 'Belgium',
        countryCode: 'BE',
        translateKey: 'BELGIUM',
        flag: '🇧🇪',
        dial_code: '+32',
        mask: '(000)000-000',
    },
    {
        name: 'Bulgaria',
        countryCode: 'BG',
        translateKey: 'BULGARIA',
        flag: '🇧🇬',
        dial_code: '+359',
        mask: '(000)000-000',
    },
    {
        name: 'Benin',
        countryCode: 'BJ',
        translateKey: 'BENIN',
        flag: '🇧🇯',
        dial_code: '+229',
        mask: '00 00 00 00',
    },
    {
        name: 'Bolivia',
        countryCode: 'BO',
        translateKey: 'BOLIVIA',
        flag: '🇧🇴',
        dial_code: '+591',
        mask: '0-000-0000',
    },
    {
        name: 'Brazil',
        countryCode: 'BR',
        translateKey: 'BRAZIL',
        flag: '🇧🇷',
        dial_code: '+55',
        mask: '(00) 00000-0000',
    },
    {
        name: 'Bahamas',
        countryCode: 'BS',
        translateKey: 'BAHAMAS',
        flag: '🇧🇸',
        dial_code: '+1242',
        mask: '000-0000',
    },
    {
        name: 'Botswana',
        countryCode: 'BW',
        translateKey: 'BOTSWANA',
        flag: '🇧🇼',
        dial_code: '+267',
        mask: '00-000-000',
    },
    {
        name: 'Belarus',
        countryCode: 'BY',
        translateKey: 'BELARUS',
        flag: '🇧🇾',
        dial_code: '+375',
        mask: '(00)000-00-00',
    },
    {
        name: 'Belize',
        countryCode: 'BZ',
        translateKey: 'BELIZE',
        flag: '🇧🇿',
        dial_code: '+501',
        mask: '000-0000',
    },
    {
        name: 'Canada',
        countryCode: 'CA',
        translateKey: 'CANADA',
        flag: '🇨🇦',
        dial_code: '+1',
        mask: '(000)000-0000',
    },
    {
        name: 'Switzerland',
        countryCode: 'CH',
        translateKey: 'SWITZERLAND',
        flag: '🇨🇭',
        dial_code: '+41',
        mask: '00-000-0000',
    },
    {
        name: 'Chile',
        countryCode: 'CL',
        translateKey: 'CHILE',
        flag: '🇨🇱',
        dial_code: '+56',
        mask: '0-0000-0000',
    },
    {
        name: 'China',
        countryCode: 'CN',
        translateKey: 'CHINA',
        flag: '🇨🇳',
        dial_code: '+86',
        mask: '(000) 0000-0000',
    },
    {
        name: 'Colombia',
        countryCode: 'CO',
        translateKey: 'COLOMBIA',
        flag: '🇨🇴',
        dial_code: '+57',
        mask: '(000)000-0000',
    },
    {
        name: 'Costa Rica',
        countryCode: 'CR',
        translateKey: 'COSTA_RICA',
        flag: '🇨🇷',
        dial_code: '+506',
        mask: '0000-0000',
    },
    {
        name: 'Cuba',
        countryCode: 'CU',
        translateKey: 'CUBA',
        flag: '🇨🇺',
        dial_code: '+53',
        mask: '0-000-0000',
    },
    {
        name: 'Cyprus',
        countryCode: 'CY',
        translateKey: 'CYPRUS',
        flag: '🇨🇾',
        dial_code: '+357',
        mask: '00-000-000',
    },
    {
        name: 'Czechia',
        countryCode: 'CZ',
        translateKey: 'CZECHIA',
        flag: '🇨🇿',
        dial_code: '+420',
        mask: '(000)000-000',
    },
    {
        name: 'Germany',
        countryCode: 'DE',
        translateKey: 'GERMANY',
        flag: '🇩🇪',
        dial_code: '+49',
        mask: '(000) 000-00009',
    },
    {
        name: 'Denmark',
        countryCode: 'DK',
        translateKey: 'DENMARK',
        flag: '🇩🇰',
        dial_code: '+45',
        mask: '00-00-00-00',
    },
    {
        name: 'Dominican Republic',
        countryCode: 'DO',
        translateKey: 'DOMINICAN_REPUBLIC',
        flag: '🇩🇴',
        dial_code: '+1849',
        mask: '000-0000',
    },
    {
        name: 'Ecuador',
        countryCode: 'EC',
        translateKey: 'ECUADOR',
        flag: '🇪🇨',
        dial_code: '+593',
        mask: '(00) 000-0000',
    },
    {
        name: 'Estonia',
        countryCode: 'EE',
        translateKey: 'ESTONIA',
        flag: '🇪🇪',
        dial_code: '+372',
        mask: '0009-0000',
    },
    {
        name: 'Egypt',
        countryCode: 'EG',
        translateKey: 'EGYPT',
        flag: '🇪🇬',
        dial_code: '+20',
        mask: '(000)000-0000',
    },
    {
        name: 'Spain',
        countryCode: 'ES',
        translateKey: 'SPAIN',
        flag: '🇪🇸',
        dial_code: '+34',
        mask: '(000)000-000',
    },
    {
        name: 'Finland',
        countryCode: 'FI',
        translateKey: 'FINLAND',
        flag: '🇫🇮',
        dial_code: '+358',
        mask: '(000)000-00-00',
    },
    {
        name: 'Faroe Islands',
        countryCode: 'FO',
        translateKey: 'FAROE_ISLANDS',
        flag: '🇫🇴',
        dial_code: '+298',
        mask: '000-000',
    },
    {
        name: 'France',
        countryCode: 'FR',
        translateKey: 'FRANCE',
        flag: '🇫🇷',
        dial_code: '+33',
        mask: '(000)000-000',
    },
    {
        name: 'Gabon',
        countryCode: 'GA',
        translateKey: 'GABON',
        flag: '🇬🇦',
        dial_code: '+241',
        mask: '0-00-00-00',
    },
    {
        name: 'United Kingdom',
        countryCode: 'GB',
        translateKey: 'UNITED_KINGDOM',
        flag: '🇬🇧',
        dial_code: '+44',
        mask: '00-0000-0000',
    },
    {
        name: 'Grenada',
        countryCode: 'GD',
        translateKey: 'GRENADA',
        flag: '🇬🇩',
        dial_code: '+1473',
        mask: '000-0000',
    },
    {
        name: 'Guernsey',
        countryCode: 'GG',
        translateKey: 'GUERNSEY',
        flag: '🇬🇬',
        dial_code: '+44',
        mask: '(0000)000000',
    },
    {
        name: 'Gibraltar',
        countryCode: 'GI',
        translateKey: 'GIBRALTAR',
        flag: '🇬🇮',
        dial_code: '+350',
        mask: '000-00000',
    },
    {
        name: 'Greenland',
        countryCode: 'GL',
        translateKey: 'GREENLAND',
        flag: '🇬🇱',
        dial_code: '+299',
        mask: '00-00-00',
    },
    {
        name: 'Gambia',
        countryCode: 'GM',
        translateKey: 'GAMBIA',
        flag: '🇬🇲',
        dial_code: '+220',
        mask: '(000)00-00',
    },
    {
        name: 'Greece',
        countryCode: 'GR',
        translateKey: 'GREECE',
        flag: '🇬🇷',
        dial_code: '+30',
        mask: '(000)000-0000',
    },
    {
        name: 'Guatemala',
        countryCode: 'GT',
        translateKey: 'GUATEMALA',
        flag: '🇬🇹',
        dial_code: '+502',
        mask: '0-000-0000',
    },
    {
        name: 'Guyana',
        countryCode: 'GY',
        translateKey: 'GUYANA',
        flag: '🇬🇾',
        dial_code: '+592',
        mask: '000-0000',
    },
    {
        name: 'Honduras',
        countryCode: 'HN',
        translateKey: 'HONDURAS',
        flag: '🇭🇳',
        dial_code: '+504',
        mask: '0000-0000',
    },
    {
        name: 'Croatia',
        countryCode: 'HR',
        translateKey: 'CROATIA',
        flag: '🇭🇷',
        dial_code: '+385',
        mask: '00-000-000',
    },
    {
        name: 'Haiti',
        countryCode: 'HT',
        translateKey: 'HAITI',
        flag: '🇭🇹',
        dial_code: '+509',
        mask: '00-00-0000',
    },
    {
        name: 'Hungary',
        countryCode: 'HU',
        translateKey: 'HUNGARY',
        flag: '🇭🇺',
        dial_code: '+36',
        mask: '(000)000-000',
    },
    {
        name: 'Indonesia',
        countryCode: 'ID',
        translateKey: 'INDONESIA',
        flag: '🇮🇩',
        dial_code: '+62',
        mask: '(000) 0009-0000',
    },
    {
        name: 'Ireland',
        countryCode: 'IE',
        translateKey: 'IRELAND',
        flag: '🇮🇪',
        dial_code: '+353',
        mask: '(000)000-000',
    },
    {
        name: 'Isle of Man',
        countryCode: 'IM',
        translateKey: 'ISLE_OF_MAN',
        flag: '🇮🇲',
        dial_code: '+44',
        mask: '(0000)000000',
    },
    {
        name: 'Iceland',
        countryCode: 'IS',
        translateKey: 'ICELAND',
        flag: '🇮🇸',
        dial_code: '+354',
        mask: '000-0000',
    },
    {
        name: 'Italy',
        countryCode: 'IT',
        translateKey: 'ITALY',
        flag: '🇮🇹',
        dial_code: '+39',
        mask: '(000)0000-000',
    },
    {
        name: 'Jersey',
        countryCode: 'JE',
        translateKey: 'JERSEY',
        flag: '🇯🇪',
        dial_code: '+44',
        mask: '(0000)0000-000000',
    },
    {
        name: 'Jamaica',
        countryCode: 'JM',
        translateKey: 'JAMAICA',
        flag: '🇯🇲',
        dial_code: '+1876',
        mask: '000-0000',
    },
    {
        name: 'Japan',
        countryCode: 'JP',
        translateKey: 'JAPAN',
        flag: '🇯🇵',
        dial_code: '+81',
        mask: '(00) 0000-0000',
    },
    {
        name: 'South Korea',
        countryCode: 'KR',
        translateKey: 'SOUTH_KOREA',
        flag: '🇰🇷',
        dial_code: '+82',
        mask: '00-000-0000',
    },
    {
        name: 'Liechtenstein',
        countryCode: 'LI',
        translateKey: 'LIECHTENSTEIN',
        flag: '🇱🇮',
        dial_code: '+423',
        mask: '(000)000-0000',
    },
    {
        name: 'Lesotho',
        countryCode: 'LS',
        translateKey: 'LESOTHO',
        flag: '🇱🇸',
        dial_code: '+266',
        mask: '0-000-0000',
    },
    {
        name: 'Lithuania',
        countryCode: 'LT',
        translateKey: 'LITHUANIA',
        flag: '🇱🇹',
        dial_code: '+370',
        mask: '(000)00-000',
    },
    {
        name: 'Luxembourg',
        countryCode: 'LU',
        translateKey: 'LUXEMBOURG',
        flag: '🇱🇺',
        dial_code: '+352',
        mask: '(000)000-000',
    },
    {
        name: 'Latvia',
        countryCode: 'LV',
        translateKey: 'LATVIA',
        flag: '🇱🇻',
        dial_code: '+371',
        mask: '00-000-000',
    },
    {
        name: 'Morocco',
        countryCode: 'MA',
        translateKey: 'MOROCCO',
        flag: '🇲🇦',
        dial_code: '+212',
        mask: '00-0000-000',
    },
    {
        name: 'Monaco',
        countryCode: 'MC',
        translateKey: 'MONACO',
        flag: '🇲🇨',
        dial_code: '+377',
        mask: '000-000-000',
    },
    {
        name: 'Moldova',
        countryCode: 'MD',
        translateKey: 'MOLDOVA',
        flag: '🇲🇩',
        dial_code: '+373',
        mask: '0000-0000',
    },
    {
        name: 'Montenegro',
        countryCode: 'ME',
        translateKey: 'MONTENEGRO',
        flag: '🇲🇪',
        dial_code: '+382',
        mask: '00-000-000',
    },
    {
        name: 'Madagascar',
        countryCode: 'MG',
        translateKey: 'MADAGASCAR',
        flag: '🇲🇬',
        dial_code: '+261',
        mask: '00-00-00000',
    },
    {
        name: 'North Macedonia',
        countryCode: 'MK',
        translateKey: 'NORTH_MACEDONIA',
        flag: '🇲🇰',
        dial_code: '+389',
        mask: '00-000-000',
    },
    {
        name: 'Mongolia',
        countryCode: 'MN',
        translateKey: 'MONGOLIA',
        flag: '🇲🇳',
        dial_code: '+976',
        mask: '00-00-0000',
    },
    {
        name: 'Montserrat',
        countryCode: 'MS',
        translateKey: 'MONTSERRAT',
        flag: '🇲🇸',
        dial_code: '+1664',
        mask: '000-0000',
    },
    {
        name: 'Malta',
        countryCode: 'MT',
        translateKey: 'MALTA',
        flag: '🇲🇹',
        dial_code: '+356',
        mask: '0000-0000',
    },
    {
        name: 'Mexico',
        countryCode: 'MX',
        translateKey: 'MEXICO',
        flag: '🇲🇽',
        dial_code: '+52',
        mask: '(000) 000-0000',
    },
    {
        name: 'Mozambique',
        countryCode: 'MZ',
        translateKey: 'MOZAMBIQUE',
        flag: '🇲🇿',
        dial_code: '+258',
        mask: '00-000-000',
    },
    {
        name: 'Namibia',
        countryCode: 'NA',
        translateKey: 'NAMIBIA',
        flag: '🇳🇦',
        dial_code: '+264',
        mask: '00-000-0000',
    },
    {
        name: 'Niger',
        countryCode: 'NE',
        translateKey: 'NIGER',
        flag: '🇳🇪',
        dial_code: '+227',
        mask: '00-00-0000',
    },
    {
        name: 'Nigeria',
        countryCode: 'NG',
        translateKey: 'NIGERIA',
        flag: '🇳🇬',
        dial_code: '+234',
        mask: '(000) 000-0000',
    },
    {
        name: 'Nicaragua',
        countryCode: 'NI',
        translateKey: 'NICARAGUA',
        flag: '🇳🇮',
        dial_code: '+505',
        mask: '0000-0000',
    },
    {
        name: 'Netherlands',
        countryCode: 'NL',
        translateKey: 'NETHERLANDS',
        flag: '🇳🇱',
        dial_code: '+31',
        mask: '00-000-0000',
    },
    {
        name: 'Norway',
        countryCode: 'NO',
        translateKey: 'NORWAY',
        flag: '🇳🇴',
        dial_code: '+47',
        mask: '(000)00-000',
    },
    {
        name: 'New Zealand',
        countryCode: 'NZ',
        translateKey: 'NEW_ZEALAND',
        flag: '🇳🇿',
        dial_code: '+64',
        mask: '(00) 000-0000',
    },
    {
        name: 'Panama',
        countryCode: 'PA',
        translateKey: 'PANAMA',
        flag: '🇵🇦',
        dial_code: '+507',
        mask: '000-0000',
    },
    {
        name: 'Peru',
        countryCode: 'PE',
        translateKey: 'PERU',
        flag: '🇵🇪',
        dial_code: '+51',
        mask: '(000)000-000',
    },
    {
        name: 'Papua New Guinea',
        countryCode: 'PG',
        translateKey: 'PAPUA_NEW_GUINEA',
        flag: '🇵🇬',
        dial_code: '+675',
        mask: '(000)00-000',
    },
    {
        name: 'Poland',
        countryCode: 'PL',
        translateKey: 'POLAND',
        flag: '🇵🇱',
        dial_code: '+48',
        mask: '(000)000-000',
    },
    {
        name: 'Puerto Rico',
        countryCode: 'PR',
        translateKey: 'PUERTO_RICO',
        flag: '🇵🇷',
        dial_code: '+1939',
        mask: '000 0000',
    },
    {
        name: 'Portugal',
        countryCode: 'PT',
        translateKey: 'PORTUGAL',
        flag: '🇵🇹',
        dial_code: '+351',
        mask: '00-000-0000',
    },
    {
        name: 'Paraguay',
        countryCode: 'PY',
        translateKey: 'PARAGUAY',
        flag: '🇵🇾',
        dial_code: '+595',
        mask: '(000)000-000',
    },
    {
        name: 'Romania',
        countryCode: 'RO',
        translateKey: 'ROMANIA',
        flag: '🇷🇴',
        dial_code: '+40',
        mask: '00-000-0000',
    },
    {
        name: 'Serbia',
        countryCode: 'RS',
        translateKey: 'SERBIA',
        flag: '🇷🇸',
        dial_code: '+381',
        mask: '00-000-0000',
    },
    {
        name: 'Russia',
        countryCode: 'RU',
        translateKey: 'RUSSIA',
        flag: '🇷🇺',
        dial_code: '+7',
        mask: '(000)000-00-00',
    },
    {
        name: 'Sweden',
        countryCode: 'SE',
        translateKey: 'SWEDEN',
        flag: '🇸🇪',
        dial_code: '+46',
        mask: '00-000-0000',
    },
    {
        name: 'Singapore',
        countryCode: 'SG',
        translateKey: 'SINGAPORE',
        flag: '🇸🇬',
        dial_code: '+65',
        mask: '0000-0000',
    },
    {
        name: 'Slovenia',
        countryCode: 'SI',
        translateKey: 'SLOVENIA',
        flag: '🇸🇮',
        dial_code: '+386',
        mask: '00-000-000',
    },
    {
        name: 'Svalbard and Jan Mayen',
        countryCode: 'SJ',
        translateKey: 'SVALBARD_AND_JAN_MAYEN',
        flag: '🇸🇯',
        dial_code: '+47',
        mask: '(000)00-000',
    },
    {
        name: 'Slovakia',
        countryCode: 'SK',
        translateKey: 'SLOVAKIA',
        flag: '🇸🇰',
        dial_code: '+421',
        mask: '(000)000-000',
    },
    {
        name: 'San Marino',
        countryCode: 'SM',
        translateKey: 'SAN_MARINO',
        flag: '🇸🇲',
        dial_code: '+378',
        mask: '0000-000000',
    },
    {
        name: 'Suriname',
        countryCode: 'SR',
        translateKey: 'SURINAME',
        flag: '🇸🇷',
        dial_code: '+597',
        mask: '000-000',
    },
    {
        name: 'El Salvador',
        countryCode: 'SV',
        translateKey: 'EL_SALVADOR',
        flag: '🇸🇻',
        dial_code: '+503',
        mask: '00-00-0000',
    },
    {
        name: 'Tunisia',
        countryCode: 'TN',
        translateKey: 'TUNISIA',
        flag: '🇹🇳',
        dial_code: '+216',
        mask: '00-000-000',
    },
    {
        name: 'Turkey',
        countryCode: 'TR',
        translateKey: 'TURKEY',
        flag: '🇹🇷',
        dial_code: '+90',
        mask: '(000)000-0000',
    },
    {
        name: 'Ukraine',
        countryCode: 'UA',
        translateKey: 'UKRAINE',
        flag: '🇺🇦',
        dial_code: '+380',
        mask: '(00)000-00-00',
    },
    {
        name: 'United States',
        countryCode: 'US',
        translateKey: 'UNITED_STATES',
        flag: '🇺🇸',
        dial_code: '+1',
        mask: '(000)000-0000',
    },
    {
        name: 'Uruguay',
        countryCode: 'UY',
        translateKey: 'URUGUAY',
        flag: '🇺🇾',
        dial_code: '+598',
        mask: '0-000-00-00',
    },
    {
        name: 'Vatican City',
        countryCode: 'VA',
        translateKey: 'VATICAN_CITY',
        flag: '🇻🇦',
        dial_code: '+379',
        mask: '00000 000000',
    },
    {
        name: 'Venezuela',
        countryCode: 'VE',
        translateKey: 'VENEZUELA',
        flag: '🇻🇪',
        dial_code: '+58',
        mask: '(000)000-0000',
    },
    {
        name: 'Vietnam',
        countryCode: 'VN',
        translateKey: 'VIETNAM',
        flag: '🇻🇳',
        dial_code: '+84',
        mask: '(00) 0000-0000',
    },
    {
        name: 'South Africa',
        countryCode: 'ZA',
        translateKey: 'SOUTH_AFRICA',
        flag: '🇿🇦',
        dial_code: '+27',
        mask: '00-000-0000',
    },
    {
        name: 'Zimbabwe',
        countryCode: 'ZW',
        translateKey: 'ZIMBABWE',
        flag: '🇿🇼',
        dial_code: '+263',
        mask: '0-000000',
    },
];
