import { type AsyncThunkAction, type UnknownAction, type createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import {
    type ThunkApiType,
    createAsyncThunkWrapper,
    dispatchOneOrMany,
    getStateQuery,
    handleAxiosError,
} from '@shared/store/store.utils';
import { type AppState } from '@store/store';
import { type ErrorResponse } from '@shared-lib/error-response/error-response.types';
import { type ErrorCb, type ServiceFunction, type ThunkCrudOrArray, type ThunkOptions } from './crud.types';
import { ACTION_TYPE_PREFIX, handleToast } from './crud.utils';
import { type DeleteThunkParams } from './delete.thunks';

export type UpdateServiceFunction<Params, Return = void> = (
    id: number,
    params: Params,
    thunkApi: ThunkApiType,
    state: AppState,
) => Promise<Return>;

export type UpdateThunkParams<Data> = Omit<DeleteThunkParams, 'needGetAll'> & {
    data: Data;
    single?: boolean;
};

export type UpdateInPlaceThunkOptions<UpdateData, Query> = ThunkOptions<
    (query: Query) => ThunkCrudOrArray,
    UpdateServiceFunction<UpdateData, void>
> & {
    errorCb?: ErrorCb;
};

export const updateInPlaceThunk = <Data, Query = unknown>({
    stateKey,
    serviceFunction,
    errorCb,
    thunksOnSuccess,
    successMessage,
}: UpdateInPlaceThunkOptions<Data, Query>): ReturnType<
    typeof createAsyncThunk<void, UpdateThunkParams<Data>, { rejectValue: UnknownAction | null }>
> => {
    return createAsyncThunkWrapper<void, UpdateThunkParams<Data>>({
        actionType: `${stateKey}/${ACTION_TYPE_PREFIX}/update-one-in-place`,
        errorCallback: errorCb || handleAxiosError,
        serviceFunction: async ({ id, data }, thunkApi, state) => {
            const { dispatch } = thunkApi;
            if (data && Object.keys(data).length > 0) {
                await serviceFunction(id, data, thunkApi, state);
                if (thunksOnSuccess) {
                    const query: Query = getStateQuery(state, stateKey);
                    dispatchOneOrMany(thunksOnSuccess(query), dispatch);
                }
            }
            handleToast(dispatch, 'success', successMessage);
        },
    });
};

type UpdateCurrentWithListThunkOptions<UpdateData, Return, Query = unknown> = ThunkOptions<
    (query: Query) => ThunkCrudOrArray,
    UpdateServiceFunction<UpdateData, Return & { slug: string }>,
    Return
> & {
    // TODO(xakeppok): unknown, PaginationQuery, object
    getItemsThunk?: (query: Query) => AsyncThunkAction<unknown, unknown, object>;
    getOneThunk?: (slug: string) => AsyncThunkAction<unknown, string, object>;
    errorCb?: ErrorCb;
};

export const updateCurrentWithListThunk = <Data, Return, Query = unknown>({
    stateKey,
    serviceFunction,
    getItemsThunk,
    getOneThunk,
    errorCb,
    thunksOnSuccess,
    successMessage,
    getName,
}: UpdateCurrentWithListThunkOptions<Data, Return, Query>): ReturnType<
    typeof createAsyncThunk<void, UpdateThunkParams<Data>, { rejectValue: UnknownAction | null }>
> => {
    return createAsyncThunkWrapper<void, UpdateThunkParams<Data>>({
        actionType: `${stateKey}/${ACTION_TYPE_PREFIX}/update-one`,
        errorCallback: errorCb || handleAxiosError,
        serviceFunction: async ({ id, data, single }, thunkApi, state) => {
            const { dispatch } = thunkApi;
            let suffixName;

            if (data && Object.keys(data).length > 0) {
                const response = await serviceFunction(id, data, thunkApi, state);
                suffixName = getName?.(response);
                const query: Query = getStateQuery(state, stateKey);
                if (!single) {
                    if (getItemsThunk) {
                        dispatch(getItemsThunk(query));
                    }
                } else {
                    if (getOneThunk) {
                        dispatch(getOneThunk(response.slug));
                    }
                }
                if (thunksOnSuccess) {
                    dispatchOneOrMany(thunksOnSuccess(query), dispatch);
                }
            }

            handleToast(dispatch, 'success', successMessage, suffixName);
        },
    });
};

type UpdateSingleThunkOptions<UpdateData, ReturnData = void> = ThunkOptions<
    (state: AppState) => ThunkCrudOrArray,
    ServiceFunction<UpdateData, ReturnData>,
    ReturnData
> & {
    errorCb?: ErrorCb;
};

export type UpdateSingleThunkParams<Data> = {
    data: Data;
};

export const updateSingleThunk = <TData extends object, ReturnData = void>({
    stateKey,
    serviceFunction,
    thunksOnSuccess,
    errorCb,
    successMessage,
    getName,
}: UpdateSingleThunkOptions<TData, ReturnData>): ReturnType<
    typeof createAsyncThunk<void, TData, { rejectValue: UnknownAction | null }>
> => {
    return createAsyncThunkWrapper<void, TData>({
        actionType: `${stateKey}/${ACTION_TYPE_PREFIX}/update-one`,
        errorCallback: (error, dispatch, state) => {
            const { status } = error.response?.data || {};
            if (status === 499 && thunksOnSuccess) {
                dispatchOneOrMany(thunksOnSuccess(state), dispatch);
                return;
            }
            return errorCb || handleAxiosError;
        },
        serviceFunction: async (data, thunkApi, state) => {
            const { dispatch } = thunkApi;
            if (Object.keys(data).length > 0) {
                try {
                    const response = await serviceFunction(data, thunkApi, state);
                    const suffixName = getName?.(response);
                    handleToast(dispatch, 'success', successMessage, suffixName);
                } catch (error) {
                    if (error && isAxiosError<ErrorResponse>(error)) {
                        const response = error.response;
                        const { status, localeKey } = response?.data ?? {};
                        if (status === 499 && localeKey) {
                            handleToast(dispatch, 'warning', localeKey);
                        } else {
                            throw error;
                        }
                    } else {
                        throw error;
                    }
                }
                if (thunksOnSuccess) {
                    dispatchOneOrMany(thunksOnSuccess(state), dispatch);
                }
            }
        },
    });
};

export const customUpdateThunk = <TData extends { id: number }, Query, ReturnData = void>({
    stateKey,
    serviceFunction,
    thunksOnSuccess,
    getItemsThunk,
    errorCb,
    successMessage,
    action,
    getName,
}: UpdateSingleThunkOptions<TData, ReturnData> & {
    getItemsThunk?: (query: Query) => AsyncThunkAction<unknown, unknown, object>;
    action: string;
}): ReturnType<typeof createAsyncThunk<void, TData, { rejectValue: UnknownAction | null }>> => {
    return createAsyncThunkWrapper<void, TData>({
        errorCallback: errorCb || handleAxiosError,
        actionType: `${stateKey}/${ACTION_TYPE_PREFIX}/update-one/${action}`,
        serviceFunction: async (data, thunkApi, state) => {
            const { dispatch } = thunkApi;
            const response = await serviceFunction(data, thunkApi, state);
            const query: Query = getStateQuery(state, stateKey);

            if (getItemsThunk) {
                dispatch(getItemsThunk(query));
            }

            const suffixName = getName?.(response);
            handleToast(dispatch, 'success', successMessage, suffixName);
            if (thunksOnSuccess) {
                dispatchOneOrMany(thunksOnSuccess(state), dispatch);
            }
        },
    });
};
