import { Language } from '@shared-lib/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import ruHeader from '@pages/dashboard/header/i18n/ru.json';
import ruHeaderUserDropdown from '@pages/dashboard/header/user-dropdown/i18n/ru.json';
import enHeader from '@pages/dashboard/header/i18n/en.json';
import enHeaderUserDropdown from '@pages/dashboard/header/user-dropdown/i18n/en.json';
import ukHeader from '@pages/dashboard/header/i18n/uk.json';
import ukHeaderUserDropdown from '@pages/dashboard/header/user-dropdown/i18n/uk.json';
import enMobileNavbar from '@pages/dashboard/header/mobile-navbar/i18n/en.json';
import ukMobileNavbar from '@pages/dashboard/header/mobile-navbar/i18n/uk.json';
import ruMobileNavbar from '@pages/dashboard/header/mobile-navbar/i18n/ru.json';

export const headerI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.HEADER]: enHeader,
        [TranslationsNameSpaces.HEADER_USER_DROPDOWN]: enHeaderUserDropdown,
        [TranslationsNameSpaces.MOBILE_NAVBAR]: enMobileNavbar,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.HEADER]: ukHeader,
        [TranslationsNameSpaces.HEADER_USER_DROPDOWN]: ukHeaderUserDropdown,
        [TranslationsNameSpaces.MOBILE_NAVBAR]: ukMobileNavbar,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.HEADER]: ruHeader,
        [TranslationsNameSpaces.HEADER_USER_DROPDOWN]: ruHeaderUserDropdown,
        [TranslationsNameSpaces.MOBILE_NAVBAR]: ruMobileNavbar,
    },
};
