import { Language } from '@shared-lib/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enSessionExpirationPopup from '@pages/dashboard/session-expiration-popup/i18n/en.json';
import ukSessionExpirationPopup from '@pages/dashboard/session-expiration-popup/i18n/uk.json';
import ruSessionExpirationPopup from '@pages/dashboard/session-expiration-popup/i18n/ru.json';

export const sessionExpirationPopupI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.SESSION_EXPIRATION_POPUP]: enSessionExpirationPopup,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.SESSION_EXPIRATION_POPUP]: ukSessionExpirationPopup,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.SESSION_EXPIRATION_POPUP]: ruSessionExpirationPopup,
    },
};
