import { Language } from '@shared-lib/language.enum.ts';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enBackups from '@backups/i18n/en.json';
import enBackupsFilters from '@backups/backups-filters/i18n/en.json';
import ukBackups from '@backups/i18n/uk.json';
import ukBackupsFilters from '@backups/backups-filters/i18n/uk.json';
import ruBackups from '@backups/i18n/ru.json';
import ruBackupsFilters from '@backups/backups-filters/i18n/ru.json';
import enInventory from '@inventory/i18n/en.json';
import enInventoryTab from '@inventory/inventory-item/inventory-tab/i18n/en.json';
import enInventoryForm from '@inventory/inventory-item/inventory-form/i18n/en.json';
import enInventoryFilers from '@inventory/inventory-filters/i18n/en.json';
import enInventoryPriceFilter from '@inventory/inventory-filters/inventory-price-filter/i18n/en.json';
import ukInventory from '@inventory/i18n/uk.json';
import ukInventoryTab from '@inventory/inventory-item/inventory-tab/i18n/uk.json';
import ukInventoryForm from '@inventory/inventory-item/inventory-form/i18n/uk.json';
import ukInventoryFilers from '@inventory/inventory-filters/i18n/uk.json';
import ukInventoryPriceFilter from '@inventory/inventory-filters/inventory-price-filter/i18n/uk.json';
import ruInventory from '@inventory/i18n/ru.json';
import ruInventoryTab from '@inventory/inventory-item/inventory-tab/i18n/ru.json';
import ruInventoryForm from '@inventory/inventory-item/inventory-form/i18n/ru.json';
import ruInventoryFilers from '@inventory/inventory-filters/i18n/ru.json';
import ruInventoryPriceFilter from '@inventory/inventory-filters/inventory-price-filter/i18n/ru.json';
import enPositions from '@positions/i18n/en.json';
import enPositionsFilters from '@positions/positions-filters/i18n/en.json';
import enPositionForm from '@positions/position-item/position-form/i18n/en.json';
import enPositionItem from '@positions/position-item/i18n/en.json';
import enPositionListItem from '@positions/position-list-item/i18n/en.json';
import enPositionInsights from '@positions/position-insights/i18n/en.json';
import enPositionTab from '@positions/position-item/position-tab/i18n/en.json';
import enPositionPermissionList from '@positions/position-item/position-form/permission-list/i18n/en.json';
import ukPositions from '@positions/i18n/uk.json';
import ukPositionForm from '@positions/position-item/position-form/i18n/uk.json';
import ukPositionsFilters from '@positions/positions-filters/i18n/uk.json';
import ukPositionItem from '@positions/position-item/i18n/uk.json';
import ukPositionListItem from '@positions/position-list-item/i18n/uk.json';
import ukPositionInsights from '@positions/position-insights/i18n/uk.json';
import ukPositionTab from '@positions/position-item/position-tab/i18n/uk.json';
import ukPositionPermissionList from '@positions/position-item/position-form/permission-list/i18n/uk.json';
import ruPositions from '@positions/i18n/ru.json';
import ruPositionForm from '@positions/position-item/position-form/i18n/ru.json';
import ruPositionsFilters from '@positions/positions-filters/i18n/ru.json';
import ruPositionItem from '@positions/position-item/i18n/ru.json';
import ruPositionListItem from '@positions/position-list-item/i18n/ru.json';
import ruPositionInsights from '@positions/position-insights/i18n/ru.json';
import ruPositionTab from '@positions/position-item/position-tab/i18n/ru.json';
import ruPositionPermissionList from '@positions/position-item/position-form/permission-list/i18n/ru.json';
import enStructure from '@structure/i18n/en.json';
import enStructureItem from '@structure/structure-item/i18n/en.json';
import ukStructure from '@structure/i18n/uk.json';
import ukStructureItem from '@structure/structure-item/i18n/uk.json';
import ruStructure from '@structure/i18n/ru.json';
import ruStructureItem from '@structure/structure-item/i18n/ru.json';
import enAttachmentsCount from '@company/shared/attachments-count/i18n/en.json';
import ukAttachmentsCount from '@company/shared/attachments-count/i18n/uk.json';
import ruAttachmentsCount from '@company/shared/attachments-count/i18n/ru.json';
import enSettingsNavigation from '@settings/settings-navigation/i18n/en.json';
import enAbsencePoliciesPopup from '@settings/shared/absence-policies/absence-policies-popup/i18n/en.json';
import enAbsencePolicySchemaHook from '@settings/shared/absence-policies/absence-policies-popup/absence-policy-schema/i18n/en.json';
import enAbsencePoliciesHook from '@settings/shared/absence-policies/absence-policies-hook/i18n/en.json';
import ukSettingsNavigation from '@settings/settings-navigation/i18n/uk.json';
import ukAbsencePoliciesPopup from '@settings/shared/absence-policies/absence-policies-popup/i18n/uk.json';
import ukAbsencePolicySchemaHook from '@settings/shared/absence-policies/absence-policies-popup/absence-policy-schema/i18n/uk.json';
import ukAbsencePoliciesHook from '@settings/shared/absence-policies/absence-policies-hook/i18n/uk.json';
import ruSettingsNavigation from '@settings/settings-navigation/i18n/ru.json';
import ruAbsencePoliciesPopup from '@settings/shared/absence-policies/absence-policies-popup/i18n/ru.json';
import ruAbsencePolicySchemaHook from '@settings/shared/absence-policies/absence-policies-popup/absence-policy-schema/i18n/ru.json';
import ruAbsencePoliciesHook from '@settings/shared/absence-policies/absence-policies-hook/i18n/ru.json';
import enWorkingWeek from '@settings-working-week/i18n/en.json';
import enWorkingWeekPopup from '@settings-working-week/working-week-popup/i18n/en.json';
import enVacationPolicy from '@settings-vacation-policy/i18n/en.json';
import enStorage from '@settings-storage/i18n/en.json';
import enStorageBar from '@settings-storage/storage-bar/i18n/en.json';
import enSicknessPolicy from '@settings-sickness-policy/i18n/en.json';
import enEmail from '@settings-mail-box/i18n/en.json';
import enEmailPopup from '@settings-mail-box/mail-box-details-popup/i18n/en.json';
import enMailBoxTestEmail from '@settings-mail-box/test-message/i18n/en.json';
import enHolidaysSettings from '@settings-holidays/i18n/en.json';
import enHolidayListItem from '@settings-holidays/holidays/holiday-list-item/i18n/en.json';
import enAddHolidaysForm from '@settings-holidays/holidays/add-holidays-form/i18n/en.json';
import enHolidaysSchedule from '@settings-holidays/holidays-schedule/i18n/en.json';
import enHolidaysSchedulePopup from '@settings-holidays/holidays-schedule/holidays-schedule-popup/i18n/en.json';
import enHolidays from '@settings-holidays/holidays/i18n/en.json';
import enHolidaysNotifications from '@settings-holidays/shared/holidays-notification/i18n/en.json';
import enGeneral from '@settings-general/i18n/en.json';
import enDatabaseBackup from '@settings-database-backup/i18n/en.json';
import enDatabaseBackupPopup from '@settings-database-backup/database-backup-details-popup/i18n/en.json';
import enGeneralPopup from '@pages/dashboard/pages/company/settings/pages/general/general-settings-popup/i18n/en.json';
import ukGeneralPopup from '@pages/dashboard/pages/company/settings/pages/general/general-settings-popup/i18n/uk.json';
import ruGeneralPopup from '@pages/dashboard/pages/company/settings/pages/general/general-settings-popup/i18n/ru.json';
import ukWorkingWeek from '@settings-working-week/i18n/uk.json';
import ukWorkingWeekPopup from '@settings-working-week/working-week-popup/i18n/uk.json';
import ukVacationPolicy from '@settings-vacation-policy/i18n/uk.json';
import ukStorage from '@settings-storage/i18n/uk.json';
import ukStorageBar from '@settings-storage/storage-bar/i18n/uk.json';
import ukSicknessPolicy from '@settings-sickness-policy/i18n/uk.json';
import ukMailBoxTestEmail from '@settings-mail-box/test-message/i18n/uk.json';
import ukEmail from '@settings-mail-box/i18n/uk.json';
import ukEmailPopup from '@settings-mail-box/mail-box-details-popup/i18n/uk.json';
import ukHolidaysSettings from '@settings-holidays/i18n/uk.json';
import ukHolidayListItem from '@settings-holidays/holidays/holiday-list-item/i18n/uk.json';
import ukAddHolidaysForm from '@settings-holidays/holidays/add-holidays-form/i18n/uk.json';
import ukHolidaysSchedule from '@settings-holidays/holidays-schedule/i18n/uk.json';
import ukHolidaysSchedulePopup from '@settings-holidays/holidays-schedule/holidays-schedule-popup/i18n/uk.json';
import ukHolidays from '@settings-holidays/holidays/i18n/uk.json';
import ukHolidaysNotifications from '@settings-holidays/shared/holidays-notification/i18n/uk.json';
import ukGeneral from '@settings-general/i18n/uk.json';
import ukDatabaseBackup from '@settings-database-backup/i18n/uk.json';
import ukDatabaseBackupPopup from '@settings-database-backup/database-backup-details-popup/i18n/uk.json';
import ruWorkingWeek from '@settings-working-week/i18n/ru.json';
import ruWorkingWeekPopup from '@settings-working-week/working-week-popup/i18n/ru.json';
import ruVacationPolicy from '@settings-vacation-policy/i18n/ru.json';
import ruStorage from '@settings-storage/i18n/ru.json';
import ruStorageBar from '@settings-storage/storage-bar/i18n/ru.json';
import ruSicknessPolicy from '@settings-sickness-policy/i18n/ru.json';
import ruMailBoxTestEmail from '@settings-mail-box/test-message/i18n/ru.json';
import ruEmail from '@settings-mail-box/i18n/ru.json';
import ruEmailPopup from '@settings-mail-box/mail-box-details-popup/i18n/ru.json';
import ruHolidaysSettings from '@settings-holidays/i18n/ru.json';
import ruHolidayListItem from '@settings-holidays/holidays/holiday-list-item/i18n/ru.json';
import ruAddHolidaysForm from '@settings-holidays/holidays/add-holidays-form/i18n/ru.json';
import ruHolidaysSchedule from '@settings-holidays/holidays-schedule/i18n/ru.json';
import ruHolidaysSchedulePopup from '@settings-holidays/holidays-schedule/holidays-schedule-popup/i18n/ru.json';
import ruHolidays from '@settings-holidays/holidays/i18n/ru.json';
import ruHolidaysNotifications from '@settings-holidays/shared/holidays-notification/i18n/ru.json';
import ruGeneral from '@settings-general/i18n/ru.json';
import ruDatabaseBackup from '@settings-database-backup/i18n/ru.json';
import ruDatabaseBackupPopup from '@settings-database-backup/database-backup-details-popup/i18n/ru.json';
import enPermissionRow from '@positions/position-item/position-form/permission-list/permission-row/i18n/en.json';
import ukPermissionRow from '@positions/position-item/position-form/permission-list/permission-row/i18n/uk.json';
import ruPermissionRow from '@positions/position-item/position-form/permission-list/permission-row/i18n/ru.json';

export const companyI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.BACKUPS]: enBackups,
        [TranslationsNameSpaces.BACKUPS_FILTERS]: enBackupsFilters,
        [TranslationsNameSpaces.INVENTORY]: enInventory,
        [TranslationsNameSpaces.INVENTORY_TAB]: enInventoryTab,
        [TranslationsNameSpaces.INVENTORY_FORM]: enInventoryForm,
        [TranslationsNameSpaces.INVENTORY_FILTERS]: enInventoryFilers,
        [TranslationsNameSpaces.INVENTORY_PRICE_FILTER]: enInventoryPriceFilter,
        [TranslationsNameSpaces.POSITIONS]: enPositions,
        [TranslationsNameSpaces.POSITIONS_FILTERS]: enPositionsFilters,
        [TranslationsNameSpaces.POSITION_FORM]: enPositionForm,
        [TranslationsNameSpaces.POSITION_ITEM]: enPositionItem,
        [TranslationsNameSpaces.POSITION_LIST_ITEM]: enPositionListItem,
        [TranslationsNameSpaces.POSITION_INSIGHTS]: enPositionInsights,
        [TranslationsNameSpaces.POSITION_TAB]: enPositionTab,
        [TranslationsNameSpaces.PERMISSION_LIST]: enPositionPermissionList,
        [TranslationsNameSpaces.PERMISSION_ROW]: enPermissionRow,
        [TranslationsNameSpaces.STRUCTURE]: enStructure,
        [TranslationsNameSpaces.STRUCTURE_ITEM]: enStructureItem,
        [TranslationsNameSpaces.ATTACHMENTS_COUNT]: enAttachmentsCount,
        [TranslationsNameSpaces.SETTINGS_NAVIGATION]: enSettingsNavigation,
        [TranslationsNameSpaces.ABSENCE_POLICIES_POPUP]: enAbsencePoliciesPopup,
        [TranslationsNameSpaces.ABSENCE_POLICY_SCHEMA_HOOK]: enAbsencePolicySchemaHook,
        [TranslationsNameSpaces.ABSENCE_POLICIES_HOOK]: enAbsencePoliciesHook,
        [TranslationsNameSpaces.WORKING_WEEK]: enWorkingWeek,
        [TranslationsNameSpaces.WORKING_WEEK_POPUP]: enWorkingWeekPopup,
        [TranslationsNameSpaces.VACATION_POLICY]: enVacationPolicy,
        [TranslationsNameSpaces.STORAGE]: enStorage,
        [TranslationsNameSpaces.STORAGE_BAR]: enStorageBar,
        [TranslationsNameSpaces.SICKNESS_POLICY]: enSicknessPolicy,
        [TranslationsNameSpaces.EMAIL]: enEmail,
        [TranslationsNameSpaces.MAIL_BOX_DETAILS_POPUP]: enEmailPopup,
        [TranslationsNameSpaces.MAIL_BOX_TEST_EMAIL]: enMailBoxTestEmail,
        [TranslationsNameSpaces.HOLIDAYS_SETTINGS]: enHolidaysSettings,
        [TranslationsNameSpaces.HOLIDAY_LIST_ITEM]: enHolidayListItem,
        [TranslationsNameSpaces.ADD_HOLIDAYS_FORM]: enAddHolidaysForm,
        [TranslationsNameSpaces.HOLIDAYS_SCHEDULE]: enHolidaysSchedule,
        [TranslationsNameSpaces.HOLIDAYS_SCHEDULE_POPUP]: enHolidaysSchedulePopup,
        [TranslationsNameSpaces.HOLIDAYS]: enHolidays,
        [TranslationsNameSpaces.HOLIDAYS_NOTIFICATIONS]: enHolidaysNotifications,
        [TranslationsNameSpaces.GENERAL]: enGeneral,
        [TranslationsNameSpaces.GENERAL_POPUP]: enGeneralPopup,
        [TranslationsNameSpaces.DATABASE_BACKUP]: enDatabaseBackup,
        [TranslationsNameSpaces.DATABASE_BACKUP_POPUP]: enDatabaseBackupPopup,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.BACKUPS]: ukBackups,
        [TranslationsNameSpaces.BACKUPS_FILTERS]: ukBackupsFilters,
        [TranslationsNameSpaces.INVENTORY]: ukInventory,
        [TranslationsNameSpaces.INVENTORY_TAB]: ukInventoryTab,
        [TranslationsNameSpaces.INVENTORY_FORM]: ukInventoryForm,
        [TranslationsNameSpaces.INVENTORY_FILTERS]: ukInventoryFilers,
        [TranslationsNameSpaces.INVENTORY_PRICE_FILTER]: ukInventoryPriceFilter,
        [TranslationsNameSpaces.POSITIONS]: ukPositions,
        [TranslationsNameSpaces.POSITION_FORM]: ukPositionForm,
        [TranslationsNameSpaces.POSITIONS_FILTERS]: ukPositionsFilters,
        [TranslationsNameSpaces.POSITION_ITEM]: ukPositionItem,
        [TranslationsNameSpaces.POSITION_LIST_ITEM]: ukPositionListItem,
        [TranslationsNameSpaces.POSITION_INSIGHTS]: ukPositionInsights,
        [TranslationsNameSpaces.POSITION_TAB]: ukPositionTab,
        [TranslationsNameSpaces.PERMISSION_LIST]: ukPositionPermissionList,
        [TranslationsNameSpaces.PERMISSION_ROW]: ukPermissionRow,
        [TranslationsNameSpaces.STRUCTURE]: ukStructure,
        [TranslationsNameSpaces.STRUCTURE_ITEM]: ukStructureItem,
        [TranslationsNameSpaces.ATTACHMENTS_COUNT]: ukAttachmentsCount,
        [TranslationsNameSpaces.SETTINGS_NAVIGATION]: ukSettingsNavigation,
        [TranslationsNameSpaces.ABSENCE_POLICIES_POPUP]: ukAbsencePoliciesPopup,
        [TranslationsNameSpaces.ABSENCE_POLICY_SCHEMA_HOOK]: ukAbsencePolicySchemaHook,
        [TranslationsNameSpaces.ABSENCE_POLICIES_HOOK]: ukAbsencePoliciesHook,
        [TranslationsNameSpaces.WORKING_WEEK]: ukWorkingWeek,
        [TranslationsNameSpaces.WORKING_WEEK_POPUP]: ukWorkingWeekPopup,
        [TranslationsNameSpaces.VACATION_POLICY]: ukVacationPolicy,
        [TranslationsNameSpaces.STORAGE]: ukStorage,
        [TranslationsNameSpaces.STORAGE_BAR]: ukStorageBar,
        [TranslationsNameSpaces.SICKNESS_POLICY]: ukSicknessPolicy,
        [TranslationsNameSpaces.MAIL_BOX_TEST_EMAIL]: ukMailBoxTestEmail,
        [TranslationsNameSpaces.EMAIL]: ukEmail,
        [TranslationsNameSpaces.MAIL_BOX_DETAILS_POPUP]: ukEmailPopup,
        [TranslationsNameSpaces.HOLIDAYS_SETTINGS]: ukHolidaysSettings,
        [TranslationsNameSpaces.HOLIDAY_LIST_ITEM]: ukHolidayListItem,
        [TranslationsNameSpaces.ADD_HOLIDAYS_FORM]: ukAddHolidaysForm,
        [TranslationsNameSpaces.HOLIDAYS_SCHEDULE]: ukHolidaysSchedule,
        [TranslationsNameSpaces.HOLIDAYS_SCHEDULE_POPUP]: ukHolidaysSchedulePopup,
        [TranslationsNameSpaces.HOLIDAYS]: ukHolidays,
        [TranslationsNameSpaces.HOLIDAYS_NOTIFICATIONS]: ukHolidaysNotifications,
        [TranslationsNameSpaces.GENERAL]: ukGeneral,
        [TranslationsNameSpaces.GENERAL_POPUP]: ukGeneralPopup,
        [TranslationsNameSpaces.DATABASE_BACKUP]: ukDatabaseBackup,
        [TranslationsNameSpaces.DATABASE_BACKUP_POPUP]: ukDatabaseBackupPopup,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.BACKUPS]: ruBackups,
        [TranslationsNameSpaces.BACKUPS_FILTERS]: ruBackupsFilters,
        [TranslationsNameSpaces.INVENTORY]: ruInventory,
        [TranslationsNameSpaces.INVENTORY_TAB]: ruInventoryTab,
        [TranslationsNameSpaces.INVENTORY_FORM]: ruInventoryForm,
        [TranslationsNameSpaces.INVENTORY_FILTERS]: ruInventoryFilers,
        [TranslationsNameSpaces.INVENTORY_PRICE_FILTER]: ruInventoryPriceFilter,
        [TranslationsNameSpaces.POSITIONS]: ruPositions,
        [TranslationsNameSpaces.POSITION_FORM]: ruPositionForm,
        [TranslationsNameSpaces.POSITIONS_FILTERS]: ruPositionsFilters,
        [TranslationsNameSpaces.POSITION_ITEM]: ruPositionItem,
        [TranslationsNameSpaces.POSITION_LIST_ITEM]: ruPositionListItem,
        [TranslationsNameSpaces.POSITION_INSIGHTS]: ruPositionInsights,
        [TranslationsNameSpaces.POSITION_TAB]: ruPositionTab,
        [TranslationsNameSpaces.PERMISSION_LIST]: ruPositionPermissionList,
        [TranslationsNameSpaces.PERMISSION_ROW]: ruPermissionRow,
        [TranslationsNameSpaces.STRUCTURE]: ruStructure,
        [TranslationsNameSpaces.STRUCTURE_ITEM]: ruStructureItem,
        [TranslationsNameSpaces.ATTACHMENTS_COUNT]: ruAttachmentsCount,
        [TranslationsNameSpaces.SETTINGS_NAVIGATION]: ruSettingsNavigation,
        [TranslationsNameSpaces.ABSENCE_POLICIES_POPUP]: ruAbsencePoliciesPopup,
        [TranslationsNameSpaces.ABSENCE_POLICY_SCHEMA_HOOK]: ruAbsencePolicySchemaHook,
        [TranslationsNameSpaces.ABSENCE_POLICIES_HOOK]: ruAbsencePoliciesHook,
        [TranslationsNameSpaces.WORKING_WEEK]: ruWorkingWeek,
        [TranslationsNameSpaces.WORKING_WEEK_POPUP]: ruWorkingWeekPopup,
        [TranslationsNameSpaces.VACATION_POLICY]: ruVacationPolicy,
        [TranslationsNameSpaces.STORAGE]: ruStorage,
        [TranslationsNameSpaces.STORAGE_BAR]: ruStorageBar,
        [TranslationsNameSpaces.SICKNESS_POLICY]: ruSicknessPolicy,
        [TranslationsNameSpaces.MAIL_BOX_TEST_EMAIL]: ruMailBoxTestEmail,
        [TranslationsNameSpaces.EMAIL]: ruEmail,
        [TranslationsNameSpaces.MAIL_BOX_DETAILS_POPUP]: ruEmailPopup,
        [TranslationsNameSpaces.HOLIDAYS_SETTINGS]: ruHolidaysSettings,
        [TranslationsNameSpaces.HOLIDAY_LIST_ITEM]: ruHolidayListItem,
        [TranslationsNameSpaces.ADD_HOLIDAYS_FORM]: ruAddHolidaysForm,
        [TranslationsNameSpaces.HOLIDAYS_SCHEDULE]: ruHolidaysSchedule,
        [TranslationsNameSpaces.HOLIDAYS_SCHEDULE_POPUP]: ruHolidaysSchedulePopup,
        [TranslationsNameSpaces.HOLIDAYS]: ruHolidays,
        [TranslationsNameSpaces.HOLIDAYS_NOTIFICATIONS]: ruHolidaysNotifications,
        [TranslationsNameSpaces.GENERAL]: ruGeneral,
        [TranslationsNameSpaces.GENERAL_POPUP]: ruGeneralPopup,
        [TranslationsNameSpaces.DATABASE_BACKUP]: ruDatabaseBackup,
        [TranslationsNameSpaces.DATABASE_BACKUP_POPUP]: ruDatabaseBackupPopup,
    },
};
