import { Language } from '@shared-lib/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enNotFound from '@pages/not-found/i18n/en.json';
import ukNotFound from '@pages/not-found/i18n/uk.json';
import ruNotFound from '@pages/not-found/i18n/ru.json';

export const notFoundI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.NOT_FOUND]: enNotFound,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.NOT_FOUND]: ukNotFound,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.NOT_FOUND]: ruNotFound,
    },
};
