//TODO(inepipenko): maybe each key should be inside of each component
export const enum TranslationsNameSpaces {
    FORMAT_BYTES = 'formatBytes',
    TRUNCATED_TEXT = 'truncatedText',
    EMPLOYEE_CIRCLE_AVATARS = 'employeeCircleAvatars',
    CHIPS = 'chips',
    SET_PASSWORD = 'setPassword',
    SET_PASSWORD_FORM = 'setPasswordForm',
    FORGOT_PASSWORD = 'forgotPassword',
    SIGN_IN = 'signIn',
    NOT_FOUND = 'notFound',
    MAINTENANCE = 'maintenance',
    NO_ACCESS = 'noAccess',
    ERROR_BOUNDARY = 'errorBoundary',
    ERROR_PAGE_WRAPPER = 'errorPageWrapper',
    HEADER = 'header',
    MOBILE_NAVBAR = 'mobileNavbar',
    HEADER_USER_DROPDOWN = 'headerUserDropdown',
    CALENDAR = 'calendar',
    INVENTORY = 'inventory',
    ATTACHMENTS_COUNT = 'attachmentsCount',
    INVENTORY_TAB = 'inventoryTab',
    EMPLOYEE = 'employee',
    EMPLOYEE_TAB = 'employeeTab',
    POSITIONS = 'positions',
    POSITIONS_FILTERS = 'positionsFilters',
    POSITION_LIST_ITEM = 'positionListItem',
    INVENTORY_FORM = 'inventoryForm',
    EMPLOYEE_FORM = 'employeeForm',
    EMPLOYEE_CHANGE_PHOTO_FORM = 'employeeChangePhotoForm',
    WEBCAM_CAPTURE = 'webcamCapture',
    CLIENTS = 'clients',
    POSITION_ITEM = 'positionItem',
    POSITION_TAB = 'positionTab',
    PROJECTS = 'projects',
    PROJECT = 'project',
    PROFILE = 'profile',
    CANDIDATES = 'candidates',
    CANDIDATE = 'candidate',
    OPEN_POSITIONS = 'openPositions',
    OPEN_POSITION = 'openPosition',
    EMPLOYEES = 'employees',
    CHARTS = 'Charts',
    RECENT_ACTIVITIES = 'recentActivities',
    FOOTER = 'footer',
    BREADCRUMBS = 'breadcrumbs',
    THEME_SWITCHER = 'themeSwitcher',
    DOCUMENT_TITLE_WITH_META = 'documentTitleWithMeta',
    SESSION_EXPIRATION_POPUP = 'sessionExpirationPopup',
    CONFIRM_MODAL = 'confirmModal',
    ACTIVITY_ACTION = 'activityAction',
    ACTIVITY_ITEM = 'activityItem',
    ACTIVITIES = 'activities',
    ACTIVITY_LOG_FILTERS = 'activityLogFilters',
    POSITION_FORM = 'positionForm',
    PERMISSION_LIST = 'permissionList',
    ACTIONS = 'actions',
    ACTIVITY_LOG_TYPES_CHART = 'activityLogTypesChart',
    SALARY_HISTORY = 'salaryHistory',
    TOAST = 'toast',
    LAYOUT_SIDE_DRAWER = 'layoutSideDrawer',
    STRUCTURE = 'structure',
    STRUCTURE_ITEM = 'structureItem',
    DASHBOARD = 'dashboard',
    POSITION_ALERT = 'positionAlert',
    SETTINGS_NAVIGATION = 'settingsNavigation',
    EMAIL = 'email',
    MAIL_BOX_DETAILS_POPUP = 'emailPopup',
    GENERAL = 'general',
    GENERAL_POPUP = 'generalPopup',
    EXPORT = 'export',
    WORKS_HISTORY = 'worksHistory',
    DATABASE_BACKUP = 'databaseBackup',
    DATABASE_BACKUP_POPUP = 'databaseBackupPopup',
    FILE_LIST_ITEM = 'fileListItem',
    FORM_INPUT = 'formInput',
    FORM_INPUT_STRONG_PASSWORD = 'formInputStrongPassword',
    DATE_PICKER = 'datePicker',
    TOOLTIP = 'tooltip',
    MAIL_BOX_TEST_EMAIL = 'mailBoxTestEmail',
    DOCUMENTS = 'documents',
    DOCUMENTS_LIST = 'documentsList',
    LINKS = 'links',
    CREDENTIALS = 'credentials',
    DOCUMENTS_FILTERS = 'documentsFilters',
    BACKUPS = 'backups',
    BACKUPS_FILTERS = 'backupsFilters',
    FORM_FILE_INPUT = 'formFileInput',
    PAGINATION = 'pagination',
    CLEAR_FILTERS = 'clearFilters',
    ENTITY_TYPE_AUTO_COMPLETE = 'entityTypeAutoComplete',
    FORM_FILE_INPUT_WRAPPER = 'FORM_FILE_INPUT_WRAPPER',
    FORM_BUTTONS = 'formButtons',
    ENTITY_TABS = 'entityTabs',
    AUTO_COMPLETE = 'autoComplete',
    FORM_FILE_IMAGES_INPUT = 'formFilesImagesInput',
    INVENTORY_FILTERS = 'inventoryFilters',
    EMPLOYEES_FILTERS = 'employeesFilters',
    EMPLOYEE_INSIGHTS = 'employeesInsights',
    POSITION_INSIGHTS = 'positionInsights',
    EMPLOYEE_LIST_ITEM = 'employeeListItem',
    IMAGES_PREVIEW = 'imagesPreview',
    INVENTORY_PRICE_FILTER = 'inventoryPriceFilter',
    DOCUMENTS_TAB = 'documentsTab',
    DOCUMENTS_TAB_ITEM = 'documentsTabItem',
    ADD_DOCUMENTS_TAB_FORM = 'addDocumentsTabForm',
    ADD_LINKS_TAB_FORM = 'addLinksTabForm',
    LINK_TAB_ITEM = 'linkTabItem',
    ACTIONS_TABS = 'actionsTabs',
    LINK_ITEM_FORM = 'linkItemForm',
    LIST_ITEM_ACTIONS = 'listItemActions',
    LINKS_TAB = 'linksTab',
    CREDENTIALS_TAB = 'credentialsTab',
    CREDENTIAL_TAB_ITEM = 'credentialTabItem',
    ADD_CREDENTIALS_TAB_FORM = 'addCredentialsTabForm',
    CREDENTIAL_ITEM_FORM = 'credentialItemForm',
    ENTITY_TABS_EDIT_ITEM_BUTTON = 'entityTabsEditItemButton',
    ENTITY_TABS_PRIVATE_SWITCHER = 'entityTabsPrivateSwitcher',
    ENTITY_TABS_UNDO_ITEM_BUTTON = 'entityTabsUndoItemButton',
    QUERY_SEARCH_FILTER = 'querySearchFilter',
    LINKS_FILTERS = 'linksFilters',
    CREDENTIALS_FILTERS = 'credentialsFilters',
    DECRYPTED_TEXT = 'decryptedText',
    COPY_TO_CLIPBOARD = 'copyToClipboard',
    COUNTRIES = 'countries',
    FORM_COUNTRY_PICKER = 'formCountryPicker',
    HOLIDAYS_SCHEDULE = 'holidaysSchedule',
    HOLIDAYS = 'holidays',
    HOLIDAYS_SCHEDULE_POPUP = 'holidaysSchedulePopup',
    HOLIDAYS_NOTIFICATIONS = 'holidaysNotifications',
    HOLIDAY_LIST_ITEM = 'holidayListItem',
    HOLIDAYS_SETTINGS = 'holidaysSettings',
    ADD_HOLIDAYS_FORM = 'addHolidaysForm',
    LINK_TAB_SCHEMA = 'linkTabSchema',
    CREDENTIAL_TAB_SCHEMA = 'credentialTabSchema',
    WORKING_WEEK = 'workingWeek',
    STORAGE = 'storage',
    STORAGE_BAR = 'storageBar',
    DATE_LISTS = 'dateLists',
    WORKING_WEEK_POPUP = 'workingWeekPopup',
    ABSENCE_POLICY_SCHEMA_HOOK = 'absencePolicySchemaHook',
    COPY_TEMPLATE_ITEM_BUTTON_MODAL = 'copyTemplateItemButtonModal',
    ABSENCE_POLICIES_POPUP = 'absencePoliciesPopup',
    ABSENCE_POLICIES_HOOK = 'absencePoliciesHook',
    VACATION_POLICY = 'vacationPolicy',
    SICKNESS_POLICY = 'sicknessPolicy',
    FILTER_WRAPPER = 'filterWrapper',
    CLIENTS_FILTERS = 'clientsFilters',
    CLIENT_FORM = 'clientForm',
    CLIENT_TAB = 'clientTab',
    CLIENTS_ALERT = 'clientsAlert',
    PERMISSION_ROW = 'permissionRow',
    PROJECT_TAB = 'projectTab',
    PROJECT_FORM = 'projectForm',
    FINISH_REOPEN_PROJECT_BUTTONS = 'finishReopenProjectButtons',
    PROJECTS_FILTERS = 'projectsFilters',
    NOTIFICATIONS = 'notifications',
    IMAGES_CAROUSEL = 'imagesCarousel',
    MOBILE_FILTERS = 'mobileFilters',
    ACTIVITY_ITEMS_CHANGES = 'activityItemChanges',
}
