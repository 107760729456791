import { Language } from '@shared-lib/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enMaintenance from '@pages/maintenance/i18n/en.json';
import ukMaintenance from '@pages/maintenance/i18n/uk.json';
import ruMaintenance from '@pages/maintenance/i18n/ru.json';

export const maintenanceI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.MAINTENANCE]: enMaintenance,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.MAINTENANCE]: ukMaintenance,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.MAINTENANCE]: ruMaintenance,
    },
};
