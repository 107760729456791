import httpClient from '@shared/http/http.service.ts';
import { type Lookup, type LookupName } from './lookup.types.ts';
import { TagType } from '@shared-lib/tags/tag-type.enum.ts';

const PREFIX = 'lookups';

export const getLookups = (lookupName: LookupName): Promise<Lookup[]> => {
    const tagTypesValues: string[] = Object.values(TagType);
    const isTag = tagTypesValues.includes(lookupName);
    let url = isTag ? 'tags/' : '';
    url += lookupName.replace(/([A-Z])/g, '-$1').toLowerCase();
    return httpClient.get(`${PREFIX}/${url}`, {});
};
