import { Language } from '@shared-lib/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enActions from '@dashboard-shared/actions/i18n/en.json';
import ukActions from '@dashboard-shared/actions/i18n/uk.json';
import ruActions from '@dashboard-shared/actions/i18n/ru.json';
import enActivityItem from '@dashboard-shared/activity-list/activity-item/i18n/en.json';
import ukActivityItem from '@dashboard-shared/activity-list/activity-item/i18n/uk.json';
import ruActivityItem from '@dashboard-shared/activity-list/activity-item/i18n/ru.json';
import enActivityAction from '@dashboard-shared/activity-list/activity-item/activity-item-actions/i18n/en.json';
import ukActivityAction from '@dashboard-shared/activity-list/activity-item/activity-item-actions/i18n/uk.json';
import ruActivityAction from '@dashboard-shared/activity-list/activity-item/activity-item-actions/i18n/ru.json';
import enActivityItemChanges from '@dashboard-shared/activity-list/activity-item/activity-item-changes/i18n/en.json';
import ruActivityItemChanges from '@dashboard-shared/activity-list/activity-item/activity-item-changes/i18n/ru.json';
import ukActivityItemChanges from '@dashboard-shared/activity-list/activity-item/activity-item-changes/i18n/uk.json';
import enImagesCarousel from '@dashboard-shared/images-carousel/i18n/en.json';
import ukImagesCarousel from '@dashboard-shared/images-carousel/i18n/uk.json';
import ruImagesCarousel from '@dashboard-shared/images-carousel/i18n/ru.json';
import enEmployeeCircleAvatars from '@dashboard-shared/employee-circle-avatars/i18n/en.json';
import ukEmployeeCircleAvatars from '@dashboard-shared/employee-circle-avatars/i18n/uk.json';
import ruEmployeeCircleAvatars from '@dashboard-shared/employee-circle-avatars/i18n/ru.json';
import enChips from '@dashboard-shared/chips/i18n/en.json';
import ukChips from '@dashboard-shared/chips/i18n/uk.json';
import ruChips from '@dashboard-shared/chips/i18n/ru.json';
import enTruncatedText from '@dashboard-shared/truncated-text/i18n/en.json';
import ukTruncatedText from '@dashboard-shared/truncated-text/i18n/uk.json';
import ruTruncatedText from '@dashboard-shared/truncated-text/i18n/ru.json';
import enCharts from '@dashboard-shared/chart/i18n/en.json';
import ukCharts from '@dashboard-shared/chart/i18n/uk.json';
import ruCharts from '@dashboard-shared/chart/i18n/ru.json';
import enSalaryHistory from '@dashboard-shared/salary-history/i18n/en.json';
import ukSalaryHistory from '@dashboard-shared/salary-history/i18n/uk.json';
import ruSalaryHistory from '@dashboard-shared/salary-history/i18n/ru.json';
import enQuerySearchFilter from '@dashboard-shared/query-search-filter/i18n/en.json';
import ukQuerySearchFilter from '@dashboard-shared/query-search-filter/i18n/uk.json';
import ruQuerySearchFilter from '@dashboard-shared/query-search-filter/i18n/ru.json';
import enPositionAlert from '@dashboard-shared/positions-alert/i18n/en.json';
import ukPositionAlert from '@dashboard-shared/positions-alert/i18n/uk.json';
import ruPositionAlert from '@dashboard-shared/positions-alert/i18n/ru.json';
import enListItemActions from '@dashboard-shared/list-item-actions/i18n/en.json';
import ukListItemActions from '@dashboard-shared/list-item-actions/i18n/uk.json';
import ruListItemActions from '@dashboard-shared/list-item-actions/i18n/ru.json';
import enImagesPreview from '@dashboard-shared/images-preview/i18n/en.json';
import ukImagesPreview from '@dashboard-shared/images-preview/i18n/uk.json';
import ruImagesPreview from '@dashboard-shared/images-preview/i18n/ru.json';
import enFormInputStrongPassword from '@dashboard-shared/form/form-input-strong-password/i18n/en.json';
import ukFormInputStrongPassword from '@dashboard-shared/form/form-input-strong-password/i18n/uk.json';
import ruFormInputStrongPassword from '@dashboard-shared/form/form-input-strong-password/i18n/ru.json';
import enFormTranslation from '@dashboard-shared/form/form-input/i18n/en.json';
import ukFormTranslation from '@dashboard-shared/form/form-input/i18n/uk.json';
import ruFormTranslation from '@dashboard-shared/form/form-input/i18n/ru.json';
import enFormCountryPicker from '@dashboard-shared/form/form-country-picker/i18n/en.json';
import ukFormCountryPicker from '@dashboard-shared/form/form-country-picker/i18n/uk.json';
import ruFormCountryPicker from '@dashboard-shared/form/form-country-picker/i18n/ru.json';
import enFileInputWrapper from '@dashboard-shared/form/file-input-wrapper/i18n/en.json';
import ukFileInputWrapper from '@dashboard-shared/form/file-input-wrapper/i18n/uk.json';
import ruFileInputWrapper from '@dashboard-shared/form/file-input-wrapper/i18n/ru.json';
import enFilterWrapper from '@dashboard-shared/filter-wrapper/i18n/en.json';
import ukFilterWrapper from '@dashboard-shared/filter-wrapper/i18n/uk.json';
import ruFilterWrapper from '@dashboard-shared/filter-wrapper/i18n/ru.json';
import enFileListItem from '@dashboard-shared/file-list/file-item-actions/i18n/en.json';
import ukFileListItem from '@dashboard-shared/file-list/file-item-actions/i18n/uk.json';
import ruFileListItem from '@dashboard-shared/file-list/file-item-actions/i18n/ru.json';
import enEntityTabsUndoItemButton from '@dashboard-shared/entity-tabs/shared/undo-item-button/i18n/en.json';
import ukEntityTabsUndoItemButton from '@dashboard-shared/entity-tabs/shared/undo-item-button/i18n/uk.json';
import ruEntityTabsUndoItemButton from '@dashboard-shared/entity-tabs/shared/undo-item-button/i18n/ru.json';
import enEntityTabsEditItemButton from '@dashboard-shared/entity-tabs/shared/edit-item-button/i18n/en.json';
import ukEntityTabsEditItemButton from '@dashboard-shared/entity-tabs/shared/edit-item-button/i18n/uk.json';
import ruEntityTabsEditItemButton from '@dashboard-shared/entity-tabs/shared/edit-item-button/i18n/ru.json';
import enEntityTabsPrivateSwitcher from '@dashboard-shared/entity-tabs/shared/private-switcher/i18n/en.json';
import ukEntityTabsPrivateSwitcher from '@dashboard-shared/entity-tabs/shared/private-switcher/i18n/uk.json';
import ruEntityTabsPrivateSwitcher from '@dashboard-shared/entity-tabs/shared/private-switcher/i18n/ru.json';
import enCredentialsTab from '@dashboard-shared/entity-tabs/credentials-tab/i18n/en.json';
import enCredentialTabItem from '@dashboard-shared/entity-tabs/credentials-tab/credential-tab-item/i18n/en.json';
import enCredentialItemForm from '@dashboard-shared/entity-tabs/credentials-tab/shared/credential-item-form/i18n/en.json';
import ukCredentialsTab from '@dashboard-shared/entity-tabs/credentials-tab/i18n/uk.json';
import ukCredentialTabItem from '@dashboard-shared/entity-tabs/credentials-tab/credential-tab-item/i18n/uk.json';
import ukCredentialItemForm from '@dashboard-shared/entity-tabs/credentials-tab/shared/credential-item-form/i18n/uk.json';
import ruCredentialsTab from '@dashboard-shared/entity-tabs/credentials-tab/i18n/ru.json';
import ruCredentialTabItem from '@dashboard-shared/entity-tabs/credentials-tab/credential-tab-item/i18n/ru.json';
import ruCredentialItemForm from '@dashboard-shared/entity-tabs/credentials-tab/shared/credential-item-form/i18n/ru.json';
import enAddCredentialsTabForm from '@dashboard-shared/entity-tabs/credentials-tab/add-credentials-tab-form/i18n/en.json';
import ukAddCredentialsTabForm from '@dashboard-shared/entity-tabs/credentials-tab/add-credentials-tab-form/i18n/uk.json';
import ruAddCredentialsTabForm from '@dashboard-shared/entity-tabs/credentials-tab/add-credentials-tab-form/i18n/ru.json';
import enNoAccess from '@dashboard-shared/no-access/i18n/en.json';
import enDatePicker from '@dashboard-shared/date-picker/i18n/en.json';
import enRecentActivities from '@dashboard-shared/recent-activities/i18n/en.json';
import enLayoutSideDrawer from '@dashboard-shared/common-drawer/layout-side-drawer/i18n/en.json';
import enDashboard from '@pages/dashboard/i18n/en.json';
import enExport from '@dashboard-shared/export/i18n/en.json';
import enWorksHistory from '@dashboard-shared/works-history/i18n/en.json';
import enDocuments from '@attachments/documents/i18n/en.json';
import enDocumentsFilters from '@attachments/documents/documents-filters/i18n/en.json';
import enFormFileInput from '@dashboard-shared/form/form-file-input/i18n/en.json';
import enPagination from '@dashboard-shared/content-wrapper/pagination/i18n/en.json';
import enClearFilters from '@dashboard-shared/clear-filters/i18n/en.json';
import enEntityTypeAutoComplete from '@dashboard-shared/query-entity-type-auto-complete/i18n/en.json';
import enFormButtons from '@dashboard-shared/form/form-buttons/i18n/en.json';
import enEntityTabs from '@dashboard-shared/entity-tabs/i18n/en.json';
import enAutoComplete from '@dashboard-shared/auto-complete/i18n/en.json';
import enFormFileImagesInput from '@dashboard-shared/form/form-file-images-input/i18n/en.json';
import enDocumentsTab from '@dashboard-shared/entity-tabs/documents-tab/i18n/en.json';
import enDocumentsTabItem from '@dashboard-shared/entity-tabs/documents-tab/documents-tab-item/i18n/en.json';
import enAddDocumentsTabForm from '@dashboard-shared/entity-tabs/documents-tab/add-documents-tab-form/i18n/en.json';
import enAddLinksTabForm from '@dashboard-shared/entity-tabs/links-tab/add-links-tab-form/i18n/en.json';
import enLinkTabItem from '@dashboard-shared/entity-tabs/links-tab/link-tab-item/i18n/en.json';
import enActionsTabs from '@dashboard-shared/tab-actions/i18n/en.json';
import enLinkItemForm from '@dashboard-shared/entity-tabs/links-tab/shared/link-item-form/i18n/en.json';
import enLinksTab from '@dashboard-shared/entity-tabs/links-tab/i18n/en.json';
import enLinks from '@attachments/links/i18n/en.json';
import enCredentials from '@attachments/credentials/i18n/en.json';
import enLinkFilters from '@attachments/links/links-filters/i18n/en.json';
import enCredentialsFilters from '@attachments/credentials/credentials-filters/i18n/en.json';
import enDecryptedText from '@dashboard-shared/decrypted-text/i18n/en.json';
import enCountries from '@dashboard-shared/countries/i18n/en.json';
import enLinkTabSchema from '@dashboard-shared/entity-tabs/links-tab/shared/link-tab-schema/i18n/en.json';
import enCredentialTabSchema from '@dashboard-shared/entity-tabs/credentials-tab/shared/credential-tab-schema/i18n/en.json';
import enDateLists from '@dashboard-shared/date/date-list/i18n/en.json';
import enCopyTemplateItemButtonModal from '@dashboard-shared/copy-template-item-button-modal/i18n/en.json';
import enDocumentList from '@attachments/documents/document-list/i18n/en.json';
import ukDatePicker from '@dashboard-shared/date-picker/i18n/uk.json';
import ukDocumentsFilters from '@attachments/documents/documents-filters/i18n/uk.json';
import ukRecentActivities from '@dashboard-shared/recent-activities/i18n/uk.json';
import ukNoAccess from '@dashboard-shared/no-access/i18n/uk.json';
import ukLayoutSideDrawer from '@dashboard-shared/common-drawer/layout-side-drawer/i18n/uk.json';
import ukDashboard from '@pages/dashboard/i18n/uk.json';
import ukExport from '@dashboard-shared/export/i18n/uk.json';
import ukWorksHistory from '@dashboard-shared/works-history/i18n/uk.json';
import ukDocuments from '@attachments/documents/i18n/uk.json';
import ukFormFileInput from '@dashboard-shared/form/form-file-input/i18n/uk.json';
import ukPagination from '@dashboard-shared/content-wrapper/pagination/i18n/uk.json';
import ukClearFilters from '@dashboard-shared/clear-filters/i18n/uk.json';
import ukEntityTypeAutoComplete from '@dashboard-shared/query-entity-type-auto-complete/i18n/uk.json';
import ukFormButtons from '@dashboard-shared/form/form-buttons/i18n/uk.json';
import ukEntityTabs from '@dashboard-shared/entity-tabs/i18n/uk.json';
import ukAutoComplete from '@dashboard-shared/auto-complete/i18n/uk.json';
import ukFormFileImagesInput from '@dashboard-shared/form/form-file-images-input/i18n/uk.json';
import ukDocumentsTab from '@dashboard-shared/entity-tabs/documents-tab/i18n/uk.json';
import ukDocumentsTabItem from '@dashboard-shared/entity-tabs/documents-tab/documents-tab-item/i18n/uk.json';
import ukAddDocumentsTabForm from '@dashboard-shared/entity-tabs/documents-tab/add-documents-tab-form/i18n/uk.json';
import ukAddLinksTabForm from '@dashboard-shared/entity-tabs/links-tab/add-links-tab-form/i18n/uk.json';
import ukLinkTabItem from '@dashboard-shared/entity-tabs/links-tab/link-tab-item/i18n/uk.json';
import ukActionsTabs from '@dashboard-shared/tab-actions/i18n/uk.json';
import ukLinkItemForm from '@dashboard-shared/entity-tabs/links-tab/shared/link-item-form/i18n/uk.json';
import ukLinksTab from '@dashboard-shared/entity-tabs/links-tab/i18n/uk.json';
import ukLinks from '@attachments/links/i18n/uk.json';
import ukCredentials from '@attachments/credentials/i18n/uk.json';
import ukLinkFilters from '@attachments/links/links-filters/i18n/uk.json';
import ukCredentialsFilters from '@attachments/credentials/credentials-filters/i18n/uk.json';
import ukDecryptedText from '@dashboard-shared/decrypted-text/i18n/uk.json';
import ukCountries from '@dashboard-shared/countries/i18n/uk.json';
import ukLinkTabSchema from '@dashboard-shared/entity-tabs/links-tab/shared/link-tab-schema/i18n/uk.json';
import ukCredentialTabSchema from '@dashboard-shared/entity-tabs/credentials-tab/shared/credential-tab-schema/i18n/uk.json';
import ukDateLists from '@dashboard-shared/date/date-list/i18n/uk.json';
import ukCopyTemplateItemButtonModal from '@dashboard-shared/copy-template-item-button-modal/i18n/uk.json';
import ukDocumentList from '@attachments/documents/document-list/i18n/uk.json';
import ruDatePicker from '@dashboard-shared/date-picker/i18n/ru.json';
import ruDocumentsFilters from '@attachments/documents/documents-filters/i18n/ru.json';
import ruRecentActivities from '@dashboard-shared/recent-activities/i18n/ru.json';
import ruNoAccess from '@dashboard-shared/no-access/i18n/ru.json';
import ruLayoutSideDrawer from '@dashboard-shared/common-drawer/layout-side-drawer/i18n/ru.json';
import ruDashboard from '@pages/dashboard/i18n/ru.json';
import ruExport from '@dashboard-shared/export/i18n/ru.json';
import ruWorksHistory from '@dashboard-shared/works-history/i18n/ru.json';
import ruDocuments from '@attachments/documents/i18n/ru.json';
import ruDocumentList from '@attachments/documents/document-list/i18n/ru.json';
import ruFormFileInput from '@dashboard-shared/form/form-file-input/i18n/ru.json';
import ruPagination from '@dashboard-shared/content-wrapper/pagination/i18n/ru.json';
import ruClearFilters from '@dashboard-shared/clear-filters/i18n/ru.json';
import ruEntityTypeAutoComplete from '@dashboard-shared/query-entity-type-auto-complete/i18n/ru.json';
import ruFormButtons from '@dashboard-shared/form/form-buttons/i18n/ru.json';
import ruEntityTabs from '@dashboard-shared/entity-tabs/i18n/ru.json';
import ruAutoComplete from '@dashboard-shared/auto-complete/i18n/ru.json';
import ruFormFileImagesInput from '@dashboard-shared/form/form-file-images-input/i18n/ru.json';
import ruDocumentsTab from '@dashboard-shared/entity-tabs/documents-tab/i18n/ru.json';
import ruDocumentsTabItem from '@dashboard-shared/entity-tabs/documents-tab/documents-tab-item/i18n/ru.json';
import ruAddDocumentsTabForm from '@dashboard-shared/entity-tabs/documents-tab/add-documents-tab-form/i18n/ru.json';
import ruAddLinksTabForm from '@dashboard-shared/entity-tabs/links-tab/add-links-tab-form/i18n/ru.json';
import ruLinkTabItem from '@dashboard-shared/entity-tabs/links-tab/link-tab-item/i18n/ru.json';
import ruActionsTabs from '@dashboard-shared/tab-actions/i18n/ru.json';
import ruLinkItemForm from '@dashboard-shared/entity-tabs/links-tab/shared/link-item-form/i18n/ru.json';
import ruLinksTab from '@dashboard-shared/entity-tabs/links-tab/i18n/ru.json';
import ruLinks from '@attachments/links/i18n/ru.json';
import ruCredentials from '@attachments/credentials/i18n/ru.json';
import ruLinkFilters from '@attachments/links/links-filters/i18n/ru.json';
import ruCredentialsFilters from '@attachments/credentials/credentials-filters/i18n/ru.json';
import ruDecryptedText from '@dashboard-shared/decrypted-text/i18n/ru.json';
import ruLinkTabSchema from '@dashboard-shared/entity-tabs/links-tab/shared/link-tab-schema/i18n/ru.json';
import ruCredentialTabSchema from '@dashboard-shared/entity-tabs/credentials-tab/shared/credential-tab-schema/i18n/ru.json';
import ruCountries from '@dashboard-shared/countries/i18n/ru.json';
import ruDateLists from '@dashboard-shared/date/date-list/i18n/ru.json';
import ruCopyTemplateItemButtonModal from '@dashboard-shared/copy-template-item-button-modal/i18n/ru.json';
import enMobileFilters from '@dashboard-shared/mobile-filters/i18n/en.json';
import ruMobileFilters from '@dashboard-shared/mobile-filters/i18n/ru.json';
import ukMobileFilters from '@dashboard-shared/mobile-filters/i18n/uk.json';

export const dashboardSharedI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.ACTIONS]: enActions,
        [TranslationsNameSpaces.ACTIVITY_ITEM]: enActivityItem,
        [TranslationsNameSpaces.ACTIVITY_ACTION]: enActivityAction,
        [TranslationsNameSpaces.IMAGES_CAROUSEL]: enImagesCarousel,
        [TranslationsNameSpaces.EMPLOYEE_CIRCLE_AVATARS]: enEmployeeCircleAvatars,
        [TranslationsNameSpaces.CHIPS]: enChips,
        [TranslationsNameSpaces.TRUNCATED_TEXT]: enTruncatedText,
        [TranslationsNameSpaces.CHARTS]: enCharts,
        [TranslationsNameSpaces.SALARY_HISTORY]: enSalaryHistory,
        [TranslationsNameSpaces.QUERY_SEARCH_FILTER]: enQuerySearchFilter,
        [TranslationsNameSpaces.POSITION_ALERT]: enPositionAlert,
        [TranslationsNameSpaces.LIST_ITEM_ACTIONS]: enListItemActions,
        [TranslationsNameSpaces.IMAGES_PREVIEW]: enImagesPreview,
        [TranslationsNameSpaces.FORM_INPUT_STRONG_PASSWORD]: enFormInputStrongPassword,
        [TranslationsNameSpaces.FORM_INPUT]: enFormTranslation,
        [TranslationsNameSpaces.FORM_COUNTRY_PICKER]: enFormCountryPicker,
        [TranslationsNameSpaces.FORM_FILE_INPUT_WRAPPER]: enFileInputWrapper,
        [TranslationsNameSpaces.FILTER_WRAPPER]: enFilterWrapper,
        [TranslationsNameSpaces.FILE_LIST_ITEM]: enFileListItem,
        [TranslationsNameSpaces.ENTITY_TABS_UNDO_ITEM_BUTTON]: enEntityTabsUndoItemButton,
        [TranslationsNameSpaces.ENTITY_TABS_EDIT_ITEM_BUTTON]: enEntityTabsEditItemButton,
        [TranslationsNameSpaces.ENTITY_TABS_PRIVATE_SWITCHER]: enEntityTabsPrivateSwitcher,
        [TranslationsNameSpaces.CREDENTIALS_TAB]: enCredentialsTab,
        [TranslationsNameSpaces.CREDENTIAL_TAB_ITEM]: enCredentialTabItem,
        [TranslationsNameSpaces.CREDENTIAL_ITEM_FORM]: enCredentialItemForm,
        [TranslationsNameSpaces.ADD_CREDENTIALS_TAB_FORM]: enAddCredentialsTabForm,
        [TranslationsNameSpaces.NO_ACCESS]: enNoAccess,
        [TranslationsNameSpaces.DATE_PICKER]: enDatePicker,
        [TranslationsNameSpaces.RECENT_ACTIVITIES]: enRecentActivities,
        [TranslationsNameSpaces.LAYOUT_SIDE_DRAWER]: enLayoutSideDrawer,
        [TranslationsNameSpaces.DASHBOARD]: enDashboard,
        [TranslationsNameSpaces.EXPORT]: enExport,
        [TranslationsNameSpaces.WORKS_HISTORY]: enWorksHistory,
        [TranslationsNameSpaces.DOCUMENTS]: enDocuments,
        [TranslationsNameSpaces.DOCUMENTS_FILTERS]: enDocumentsFilters,
        [TranslationsNameSpaces.FORM_FILE_INPUT]: enFormFileInput,
        [TranslationsNameSpaces.PAGINATION]: enPagination,
        [TranslationsNameSpaces.CLEAR_FILTERS]: enClearFilters,
        [TranslationsNameSpaces.ENTITY_TYPE_AUTO_COMPLETE]: enEntityTypeAutoComplete,
        [TranslationsNameSpaces.FORM_BUTTONS]: enFormButtons,
        [TranslationsNameSpaces.ENTITY_TABS]: enEntityTabs,
        [TranslationsNameSpaces.AUTO_COMPLETE]: enAutoComplete,
        [TranslationsNameSpaces.FORM_FILE_IMAGES_INPUT]: enFormFileImagesInput,
        [TranslationsNameSpaces.DOCUMENTS_TAB]: enDocumentsTab,
        [TranslationsNameSpaces.DOCUMENTS_TAB_ITEM]: enDocumentsTabItem,
        [TranslationsNameSpaces.ADD_DOCUMENTS_TAB_FORM]: enAddDocumentsTabForm,
        [TranslationsNameSpaces.ADD_LINKS_TAB_FORM]: enAddLinksTabForm,
        [TranslationsNameSpaces.LINK_TAB_ITEM]: enLinkTabItem,
        [TranslationsNameSpaces.ACTIONS_TABS]: enActionsTabs,
        [TranslationsNameSpaces.LINK_ITEM_FORM]: enLinkItemForm,
        [TranslationsNameSpaces.LINKS_TAB]: enLinksTab,
        [TranslationsNameSpaces.LINKS]: enLinks,
        [TranslationsNameSpaces.CREDENTIALS]: enCredentials,
        [TranslationsNameSpaces.LINKS_FILTERS]: enLinkFilters,
        [TranslationsNameSpaces.CREDENTIALS_FILTERS]: enCredentialsFilters,
        [TranslationsNameSpaces.DECRYPTED_TEXT]: enDecryptedText,
        [TranslationsNameSpaces.COUNTRIES]: enCountries,
        [TranslationsNameSpaces.LINK_TAB_SCHEMA]: enLinkTabSchema,
        [TranslationsNameSpaces.CREDENTIAL_TAB_SCHEMA]: enCredentialTabSchema,
        [TranslationsNameSpaces.DATE_LISTS]: enDateLists,
        [TranslationsNameSpaces.COPY_TEMPLATE_ITEM_BUTTON_MODAL]: enCopyTemplateItemButtonModal,
        [TranslationsNameSpaces.DOCUMENTS_LIST]: enDocumentList,
        [TranslationsNameSpaces.MOBILE_FILTERS]: enMobileFilters,
        [TranslationsNameSpaces.ACTIVITY_ITEMS_CHANGES]: enActivityItemChanges,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.ACTIONS]: ukActions,
        [TranslationsNameSpaces.ACTIVITY_ITEM]: ukActivityItem,
        [TranslationsNameSpaces.ACTIVITY_ACTION]: ukActivityAction,
        [TranslationsNameSpaces.IMAGES_CAROUSEL]: ukImagesCarousel,
        [TranslationsNameSpaces.EMPLOYEE_CIRCLE_AVATARS]: ukEmployeeCircleAvatars,
        [TranslationsNameSpaces.CHIPS]: ukChips,
        [TranslationsNameSpaces.TRUNCATED_TEXT]: ukTruncatedText,
        [TranslationsNameSpaces.CHARTS]: ukCharts,
        [TranslationsNameSpaces.SALARY_HISTORY]: ukSalaryHistory,
        [TranslationsNameSpaces.QUERY_SEARCH_FILTER]: ukQuerySearchFilter,
        [TranslationsNameSpaces.POSITION_ALERT]: ukPositionAlert,
        [TranslationsNameSpaces.LIST_ITEM_ACTIONS]: ukListItemActions,
        [TranslationsNameSpaces.IMAGES_PREVIEW]: ukImagesPreview,
        [TranslationsNameSpaces.FORM_INPUT_STRONG_PASSWORD]: ukFormInputStrongPassword,
        [TranslationsNameSpaces.FORM_INPUT]: ukFormTranslation,
        [TranslationsNameSpaces.FORM_COUNTRY_PICKER]: ukFormCountryPicker,
        [TranslationsNameSpaces.FORM_FILE_INPUT_WRAPPER]: ukFileInputWrapper,
        [TranslationsNameSpaces.FILTER_WRAPPER]: ukFilterWrapper,
        [TranslationsNameSpaces.FILE_LIST_ITEM]: ukFileListItem,
        [TranslationsNameSpaces.ENTITY_TABS_UNDO_ITEM_BUTTON]: ukEntityTabsUndoItemButton,
        [TranslationsNameSpaces.ENTITY_TABS_EDIT_ITEM_BUTTON]: ukEntityTabsEditItemButton,
        [TranslationsNameSpaces.ENTITY_TABS_PRIVATE_SWITCHER]: ukEntityTabsPrivateSwitcher,
        [TranslationsNameSpaces.CREDENTIALS_TAB]: ukCredentialsTab,
        [TranslationsNameSpaces.CREDENTIAL_TAB_ITEM]: ukCredentialTabItem,
        [TranslationsNameSpaces.CREDENTIAL_ITEM_FORM]: ukCredentialItemForm,
        [TranslationsNameSpaces.ADD_CREDENTIALS_TAB_FORM]: ukAddCredentialsTabForm,
        [TranslationsNameSpaces.DATE_PICKER]: ukDatePicker,
        [TranslationsNameSpaces.DOCUMENTS_FILTERS]: ukDocumentsFilters,
        [TranslationsNameSpaces.RECENT_ACTIVITIES]: ukRecentActivities,
        [TranslationsNameSpaces.NO_ACCESS]: ukNoAccess,
        [TranslationsNameSpaces.LAYOUT_SIDE_DRAWER]: ukLayoutSideDrawer,
        [TranslationsNameSpaces.DASHBOARD]: ukDashboard,
        [TranslationsNameSpaces.EXPORT]: ukExport,
        [TranslationsNameSpaces.WORKS_HISTORY]: ukWorksHistory,
        [TranslationsNameSpaces.DOCUMENTS]: ukDocuments,
        [TranslationsNameSpaces.FORM_FILE_INPUT]: ukFormFileInput,
        [TranslationsNameSpaces.PAGINATION]: ukPagination,
        [TranslationsNameSpaces.CLEAR_FILTERS]: ukClearFilters,
        [TranslationsNameSpaces.ENTITY_TYPE_AUTO_COMPLETE]: ukEntityTypeAutoComplete,
        [TranslationsNameSpaces.FORM_BUTTONS]: ukFormButtons,
        [TranslationsNameSpaces.ENTITY_TABS]: ukEntityTabs,
        [TranslationsNameSpaces.AUTO_COMPLETE]: ukAutoComplete,
        [TranslationsNameSpaces.FORM_FILE_IMAGES_INPUT]: ukFormFileImagesInput,
        [TranslationsNameSpaces.DOCUMENTS_TAB]: ukDocumentsTab,
        [TranslationsNameSpaces.DOCUMENTS_TAB_ITEM]: ukDocumentsTabItem,
        [TranslationsNameSpaces.ADD_DOCUMENTS_TAB_FORM]: ukAddDocumentsTabForm,
        [TranslationsNameSpaces.ADD_LINKS_TAB_FORM]: ukAddLinksTabForm,
        [TranslationsNameSpaces.LINK_TAB_ITEM]: ukLinkTabItem,
        [TranslationsNameSpaces.ACTIONS_TABS]: ukActionsTabs,
        [TranslationsNameSpaces.LINK_ITEM_FORM]: ukLinkItemForm,
        [TranslationsNameSpaces.LINKS_TAB]: ukLinksTab,
        [TranslationsNameSpaces.LINKS]: ukLinks,
        [TranslationsNameSpaces.CREDENTIALS]: ukCredentials,
        [TranslationsNameSpaces.LINKS_FILTERS]: ukLinkFilters,
        [TranslationsNameSpaces.CREDENTIALS_FILTERS]: ukCredentialsFilters,
        [TranslationsNameSpaces.DECRYPTED_TEXT]: ukDecryptedText,
        [TranslationsNameSpaces.COUNTRIES]: ukCountries,
        [TranslationsNameSpaces.LINK_TAB_SCHEMA]: ukLinkTabSchema,
        [TranslationsNameSpaces.CREDENTIAL_TAB_SCHEMA]: ukCredentialTabSchema,
        [TranslationsNameSpaces.DATE_LISTS]: ukDateLists,
        [TranslationsNameSpaces.COPY_TEMPLATE_ITEM_BUTTON_MODAL]: ukCopyTemplateItemButtonModal,
        [TranslationsNameSpaces.DOCUMENTS_LIST]: ukDocumentList,
        [TranslationsNameSpaces.MOBILE_FILTERS]: ukMobileFilters,
        [TranslationsNameSpaces.ACTIVITY_ITEMS_CHANGES]: ukActivityItemChanges,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.ACTIONS]: ruActions,
        [TranslationsNameSpaces.ACTIVITY_ITEM]: ruActivityItem,
        [TranslationsNameSpaces.ACTIVITY_ACTION]: ruActivityAction,
        [TranslationsNameSpaces.IMAGES_CAROUSEL]: ruImagesCarousel,
        [TranslationsNameSpaces.EMPLOYEE_CIRCLE_AVATARS]: ruEmployeeCircleAvatars,
        [TranslationsNameSpaces.CHIPS]: ruChips,
        [TranslationsNameSpaces.TRUNCATED_TEXT]: ruTruncatedText,
        [TranslationsNameSpaces.CHARTS]: ruCharts,
        [TranslationsNameSpaces.SALARY_HISTORY]: ruSalaryHistory,
        [TranslationsNameSpaces.QUERY_SEARCH_FILTER]: ruQuerySearchFilter,
        [TranslationsNameSpaces.POSITION_ALERT]: ruPositionAlert,
        [TranslationsNameSpaces.LIST_ITEM_ACTIONS]: ruListItemActions,
        [TranslationsNameSpaces.IMAGES_PREVIEW]: ruImagesPreview,
        [TranslationsNameSpaces.FORM_INPUT_STRONG_PASSWORD]: ruFormInputStrongPassword,
        [TranslationsNameSpaces.FORM_INPUT]: ruFormTranslation,
        [TranslationsNameSpaces.FORM_COUNTRY_PICKER]: ruFormCountryPicker,
        [TranslationsNameSpaces.FORM_FILE_INPUT_WRAPPER]: ruFileInputWrapper,
        [TranslationsNameSpaces.FILTER_WRAPPER]: ruFilterWrapper,
        [TranslationsNameSpaces.FILE_LIST_ITEM]: ruFileListItem,
        [TranslationsNameSpaces.ENTITY_TABS_UNDO_ITEM_BUTTON]: ruEntityTabsUndoItemButton,
        [TranslationsNameSpaces.ENTITY_TABS_EDIT_ITEM_BUTTON]: ruEntityTabsEditItemButton,
        [TranslationsNameSpaces.ENTITY_TABS_PRIVATE_SWITCHER]: ruEntityTabsPrivateSwitcher,
        [TranslationsNameSpaces.CREDENTIALS_TAB]: ruCredentialsTab,
        [TranslationsNameSpaces.CREDENTIAL_TAB_ITEM]: ruCredentialTabItem,
        [TranslationsNameSpaces.CREDENTIAL_ITEM_FORM]: ruCredentialItemForm,
        [TranslationsNameSpaces.ADD_CREDENTIALS_TAB_FORM]: ruAddCredentialsTabForm,
        [TranslationsNameSpaces.DATE_PICKER]: ruDatePicker,
        [TranslationsNameSpaces.DOCUMENTS_FILTERS]: ruDocumentsFilters,
        [TranslationsNameSpaces.RECENT_ACTIVITIES]: ruRecentActivities,
        [TranslationsNameSpaces.NO_ACCESS]: ruNoAccess,
        [TranslationsNameSpaces.LAYOUT_SIDE_DRAWER]: ruLayoutSideDrawer,
        [TranslationsNameSpaces.DASHBOARD]: ruDashboard,
        [TranslationsNameSpaces.EXPORT]: ruExport,
        [TranslationsNameSpaces.WORKS_HISTORY]: ruWorksHistory,
        [TranslationsNameSpaces.DOCUMENTS]: ruDocuments,
        [TranslationsNameSpaces.DOCUMENTS_LIST]: ruDocumentList,
        [TranslationsNameSpaces.FORM_FILE_INPUT]: ruFormFileInput,
        [TranslationsNameSpaces.PAGINATION]: ruPagination,
        [TranslationsNameSpaces.CLEAR_FILTERS]: ruClearFilters,
        [TranslationsNameSpaces.ENTITY_TYPE_AUTO_COMPLETE]: ruEntityTypeAutoComplete,
        [TranslationsNameSpaces.FORM_BUTTONS]: ruFormButtons,
        [TranslationsNameSpaces.ENTITY_TABS]: ruEntityTabs,
        [TranslationsNameSpaces.AUTO_COMPLETE]: ruAutoComplete,
        [TranslationsNameSpaces.FORM_FILE_IMAGES_INPUT]: ruFormFileImagesInput,
        [TranslationsNameSpaces.DOCUMENTS_TAB]: ruDocumentsTab,
        [TranslationsNameSpaces.DOCUMENTS_TAB_ITEM]: ruDocumentsTabItem,
        [TranslationsNameSpaces.ADD_DOCUMENTS_TAB_FORM]: ruAddDocumentsTabForm,
        [TranslationsNameSpaces.ADD_LINKS_TAB_FORM]: ruAddLinksTabForm,
        [TranslationsNameSpaces.LINK_TAB_ITEM]: ruLinkTabItem,
        [TranslationsNameSpaces.ACTIONS_TABS]: ruActionsTabs,
        [TranslationsNameSpaces.LINK_ITEM_FORM]: ruLinkItemForm,
        [TranslationsNameSpaces.LINKS_TAB]: ruLinksTab,
        [TranslationsNameSpaces.LINKS]: ruLinks,
        [TranslationsNameSpaces.CREDENTIALS]: ruCredentials,
        [TranslationsNameSpaces.LINKS_FILTERS]: ruLinkFilters,
        [TranslationsNameSpaces.CREDENTIALS_FILTERS]: ruCredentialsFilters,
        [TranslationsNameSpaces.DECRYPTED_TEXT]: ruDecryptedText,
        [TranslationsNameSpaces.LINK_TAB_SCHEMA]: ruLinkTabSchema,
        [TranslationsNameSpaces.CREDENTIAL_TAB_SCHEMA]: ruCredentialTabSchema,
        [TranslationsNameSpaces.COUNTRIES]: ruCountries,
        [TranslationsNameSpaces.DATE_LISTS]: ruDateLists,
        [TranslationsNameSpaces.COPY_TEMPLATE_ITEM_BUTTON_MODAL]: ruCopyTemplateItemButtonModal,
        [TranslationsNameSpaces.MOBILE_FILTERS]: ruMobileFilters,
        [TranslationsNameSpaces.ACTIVITY_ITEMS_CHANGES]: ruActivityItemChanges,
    },
};
