import { ErrorKeys } from '@shared-lib/error-response/error-keys.enum';
import { type ErrorResponse } from '@shared-lib/error-response/error-response.types';
import { type ActionCrudOrArray, type ThunkCrudOrArray } from '@pages/dashboard/shared/crud/crud.types';
import { handleToast } from '@pages/dashboard/shared/crud/crud.utils';
import { type PayloadAction, type UnknownAction, createAsyncThunk, isFulfilled, isRejected } from '@reduxjs/toolkit';
import { type DispatchType, type StateKey } from '@store/state.types';
import { type AppState } from '@store/store';
import { type AxiosError } from 'axios';
import get from 'lodash/get';

// TODO(xakeppok): maybe we need delete all these matches
function isMatchedKey(action: UnknownAction, stateKey: string): boolean {
    if (stateKey) {
        return action.type.includes(stateKey);
    }
    return true;
}

type KeysMatchingType<T, U> = {
    [K in keyof T]: T[K] extends U ? K : never;
}[keyof T];

export const setStateValue = <T, U>(key: KeysMatchingType<T, U>, value: U) => {
    return (state: T): void => {
        state[key] = value as T[KeysMatchingType<T, U>];
    };
};

export function isRejectedAction(action: UnknownAction, stateKey: string): action is UnknownAction {
    return isMatchedKey(action, stateKey) && action.type.endsWith('rejected');
}

export function isPendingWithSkipActions(
    action: UnknownAction,
    stateKey: string,
    skipActions: string[] = [],
): action is UnknownAction {
    return (
        isMatchedKey(action, stateKey) &&
        action.type.endsWith('pending') &&
        skipActions.every((actionType) => !action.type.includes(actionType))
    );
}

export function isPendingActions(action: UnknownAction, stateKey: string, actions: string[]): action is UnknownAction {
    return (
        isMatchedKey(action, stateKey) &&
        action.type.endsWith('pending') &&
        actions.some((actionType) => action.type.includes(actionType))
    );
}

export function isRejectedActions(action: UnknownAction, stateKey: string, actions: string[]): action is UnknownAction {
    return isRejectedAction(action, stateKey) && actions.some((actionType) => action.type.includes(actionType));
}

export function isFulfilledAction(action: UnknownAction, stateKey: string): action is UnknownAction {
    return isMatchedKey(action, stateKey) && action.type.endsWith('fulfilled');
}

export type ThunkApiType = {
    dispatch: DispatchType;
    rejectWithValue: (value: UnknownAction | null) => void;
};

type AsyncThunkWrapperOptions<P, R> = {
    actionType: string;
    errorCallback?: (error: AxiosError<ErrorResponse, unknown>, dispatch: DispatchType, state: AppState) => void;
    // If you will add more, please make object
    serviceFunction: (params: P, thunkApi: ThunkApiType, state: AppState) => Promise<R>;
};

export const handleAxiosError = (error: AxiosError<ErrorResponse, unknown>, dispatch: DispatchType): void => {
    const localeKey = error.response?.data?.localeKey;
    handleToast(dispatch, 'error', `ERRORS.${localeKey || ErrorKeys.UNKNOWN_ERROR}`);
};

export const createAsyncThunkWrapper = <R, P>(
    options: AsyncThunkWrapperOptions<P, R>,
): ReturnType<typeof createAsyncThunk<R, P, { rejectValue: UnknownAction | null }>> => {
    const { actionType, serviceFunction, errorCallback } = options;

    return createAsyncThunk<R, P, { rejectValue: any }>(actionType, async (params, thunkApi) => {
        const { dispatch, rejectWithValue, getState } = thunkApi;
        const state = getState();
        try {
            return await serviceFunction(params, thunkApi, state as AppState);
        } catch (error) {
            const err = error as any;
            const canceledError = 'CanceledError';
            const localeKey = err.response?.data?.localeKey;

            if (err.response?.status === 499 && localeKey) {
                handleToast(dispatch, 'warning', localeKey);
            }

            if (err.name !== canceledError) {
                if (errorCallback) {
                    errorCallback(err, dispatch, state as AppState);
                } else {
                    handleAxiosError(err, dispatch);
                }
            }

            return rejectWithValue(err?.response?.data);
        }
    });
};

const warningCode = 499;
export const handleWarning = (resultAction: PayloadAction<unknown> | UnknownAction): boolean =>
    (isRejected(resultAction) && (resultAction.payload as any)?.status === warningCode) || !!isFulfilled(resultAction);

export const dispatchOneOrMany = async (
    thunksToDispatch: ThunkCrudOrArray | ActionCrudOrArray,
    dispatch: DispatchType,
    cbOnSuccess?: () => void,
): Promise<void> => {
    if (Array.isArray(thunksToDispatch)) {
        const resultActions = await Promise.all(thunksToDispatch.map((action) => dispatch(action)));

        if (cbOnSuccess && resultActions.every((resultAction) => handleWarning(resultAction))) {
            cbOnSuccess();
        }
    } else if (thunksToDispatch) {
        const resultAction = await dispatch(thunksToDispatch);

        if (handleWarning(resultAction)) {
            cbOnSuccess?.();
        }
    }
};

export const getStateQuery = <Query>(state: AppState, stateKey: StateKey): Query => {
    let query = {} as Query;
    if (stateKey) {
        const statePart = get(state, stateKey);
        if (stateKey && 'query' in statePart) {
            query = statePart.query as Query;
        }
    }
    return query;
};
