import { Language } from '@shared-lib/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enEmployeesFilers from '@employees/employees-filters/i18n/en.json';
import enEmployeesInsights from '@employees/employee-insights/i18n/en.json';
import enEmployeeListItem from '@employees/employee-list-item/i18n/en.json';
import ukEmployeesFilers from '@employees/employees-filters/i18n/uk.json';
import ukEmployeesInsights from '@employees/employee-insights/i18n/uk.json';
import ukEmployeeListItem from '@employees/employee-list-item/i18n/uk.json';
import ruEmployeesFilers from '@employees/employees-filters/i18n/ru.json';
import ruEmployeesInsights from '@employees/employee-insights/i18n/ru.json';
import ruEmployeeListItem from '@employees/employee-list-item/i18n/ru.json';
import enEmployees from '@employees/i18n/en.json';
import enEmployeeTab from '@employees/employee/employee-tab/i18n/en.json';
import enEmployee from '@employees/employee/i18n/en.json';
import ukEmployees from '@employees/i18n/uk.json';
import ukEmployeeTab from '@employees/employee/employee-tab/i18n/uk.json';
import ukEmployee from '@employees/employee/i18n/uk.json';
import ruEmployees from '@employees/i18n/ru.json';
import ruEmployeeTab from '@employees/employee/employee-tab/i18n/ru.json';
import ruEmployee from '@employees/employee/i18n/ru.json';
import enEmployeeForm from '@employees/employee/employee-form/i18n/en.json';
import enEmployeeChangePhotoForm from '@employees/employee/employee-change-photo-form/i18n/en.json';
import enWebcamCapture from '@employees/employee/employee-change-photo-form/webcam-capture/i18n/en.json';
import ukEmployeeForm from '@employees/employee/employee-form/i18n/uk.json';
import ukEmployeeChangePhotoForm from '@employees/employee/employee-change-photo-form/i18n/uk.json';
import ukWebcamCapture from '@employees/employee/employee-change-photo-form/webcam-capture/i18n/uk.json';
import ruEmployeeForm from '@employees/employee/employee-form/i18n/ru.json';
import ruEmployeeChangePhotoForm from '@employees/employee/employee-change-photo-form/i18n/ru.json';
import ruWebcamCapture from '@employees/employee/employee-change-photo-form/webcam-capture/i18n/ru.json';

export const teamI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.EMPLOYEES]: enEmployees,
        [TranslationsNameSpaces.EMPLOYEE_TAB]: enEmployeeTab,
        [TranslationsNameSpaces.EMPLOYEE]: enEmployee,
        [TranslationsNameSpaces.EMPLOYEES_FILTERS]: enEmployeesFilers,
        [TranslationsNameSpaces.EMPLOYEE_INSIGHTS]: enEmployeesInsights,
        [TranslationsNameSpaces.EMPLOYEE_LIST_ITEM]: enEmployeeListItem,
        [TranslationsNameSpaces.EMPLOYEE_FORM]: enEmployeeForm,
        [TranslationsNameSpaces.EMPLOYEE_CHANGE_PHOTO_FORM]: enEmployeeChangePhotoForm,
        [TranslationsNameSpaces.WEBCAM_CAPTURE]: enWebcamCapture,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.EMPLOYEES]: ukEmployees,
        [TranslationsNameSpaces.EMPLOYEE_TAB]: ukEmployeeTab,
        [TranslationsNameSpaces.EMPLOYEE]: ukEmployee,
        [TranslationsNameSpaces.EMPLOYEES_FILTERS]: ukEmployeesFilers,
        [TranslationsNameSpaces.EMPLOYEE_INSIGHTS]: ukEmployeesInsights,
        [TranslationsNameSpaces.EMPLOYEE_LIST_ITEM]: ukEmployeeListItem,
        [TranslationsNameSpaces.EMPLOYEE_FORM]: ukEmployeeForm,
        [TranslationsNameSpaces.EMPLOYEE_CHANGE_PHOTO_FORM]: ukEmployeeChangePhotoForm,
        [TranslationsNameSpaces.WEBCAM_CAPTURE]: ukWebcamCapture,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.EMPLOYEES]: ruEmployees,
        [TranslationsNameSpaces.EMPLOYEE_TAB]: ruEmployeeTab,
        [TranslationsNameSpaces.EMPLOYEE]: ruEmployee,
        [TranslationsNameSpaces.EMPLOYEES_FILTERS]: ruEmployeesFilers,
        [TranslationsNameSpaces.EMPLOYEE_INSIGHTS]: ruEmployeesInsights,
        [TranslationsNameSpaces.EMPLOYEE_LIST_ITEM]: ruEmployeeListItem,
        [TranslationsNameSpaces.EMPLOYEE_FORM]: ruEmployeeForm,
        [TranslationsNameSpaces.EMPLOYEE_CHANGE_PHOTO_FORM]: ruEmployeeChangePhotoForm,
        [TranslationsNameSpaces.WEBCAM_CAPTURE]: ruWebcamCapture,
    },
};
