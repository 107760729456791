import { Language } from '@shared-lib/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enActivities from '@activity-log/i18n/en.json';
import ukActivities from '@activity-log/i18n/uk.json';
import ruActivities from '@activity-log/i18n/ru.json';
import enActivityLogFilters from '@activity-log/activity-log-filters/i18n/en.json';
import ukActivityLogFilters from '@activity-log/activity-log-filters/i18n/uk.json';
import ruActivityLogFilters from '@activity-log/activity-log-filters/i18n/ru.json';
import enActivityLogTypesChart from '@activity-log/activity-log-types-chart/i18n/en.json';
import ukActivityLogTypesChart from '@activity-log/activity-log-types-chart/i18n/uk.json';
import ruActivityLogTypesChart from '@activity-log/activity-log-types-chart/i18n/ru.json';

export const activityLogI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.ACTIVITIES]: enActivities,
        [TranslationsNameSpaces.ACTIVITY_LOG_FILTERS]: enActivityLogFilters,
        [TranslationsNameSpaces.ACTIVITY_LOG_TYPES_CHART]: enActivityLogTypesChart,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.ACTIVITIES]: ukActivities,
        [TranslationsNameSpaces.ACTIVITY_LOG_FILTERS]: ukActivityLogFilters,
        [TranslationsNameSpaces.ACTIVITY_LOG_TYPES_CHART]: ukActivityLogTypesChart,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.ACTIVITIES]: ruActivities,
        [TranslationsNameSpaces.ACTIVITY_LOG_FILTERS]: ruActivityLogFilters,
        [TranslationsNameSpaces.ACTIVITY_LOG_TYPES_CHART]: ruActivityLogTypesChart,
    },
};
