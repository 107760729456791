import saveAs from 'file-saver';
import { BASE_URL } from '@shared/http/http.service';
import { type EntityType } from '@shared-lib/entity-type/entity-type.enum';
import { type NullableString } from '../documents/store/documents.types';
import { ImageType } from '@shared/image/image.enum';
import type { Image } from '@shared/image/image.types';
import type { GeneralSettingsIconsPath } from '@shared-lib/general-settings/general-settings-icons-path.enum';

type ImageParams = {
    image?: Image | null | undefined;
    url?: string | null | undefined;
    type?: ImageType;
};

type GetFileParams = {
    entityType?: EntityType;
    // TODO(xakeppok): make enum with ApiRoutes and use it on front end backend
    apiRoute?: string;
    fileName?: string | null;
    imageParams?: ImageParams;
};

export const getFileLink = ({ fileName, entityType, imageParams, apiRoute }: GetFileParams): string | undefined => {
    const { url, type = ImageType.FULL, image } = imageParams || {};
    let directFileUrl = url ?? fileName;

    if (image) {
        switch (type) {
            case ImageType.FULL:
                directFileUrl = image.fileName;
                break;
            case ImageType.ICON:
                directFileUrl = image.iconFileName;
                break;
            case ImageType.THUMBNAIL:
                directFileUrl = image.thumbnailFileName;
                break;
        }
    }
    if (!directFileUrl) {
        return;
    }
    return `${BASE_URL}/${entityType ?? apiRoute}/${imageParams ? 'images/' : ''}${directFileUrl}`;
};

export const getLogoUrl = (url: GeneralSettingsIconsPath): string =>
    getFileLink({
        apiRoute: 'general-settings',
        imageParams: { url },
    }) ?? '';

export const dummyQuery = (url: string): string => {
    return `${url}?v=${new Date().getTime()}`;
};

type DownloadFileParams = {
    fileName: string;
    displayName: NullableString;
    entityType: EntityType;
    extension: NullableString;
};

// TODO(xakeppok): maybe remove extension, fileName will be enough ?
export const downloadFile = async ({
    entityType,
    fileName,
    displayName,
    extension,
}: DownloadFileParams): Promise<void> => {
    const link = getFileLink({ entityType, fileName });
    if (!link) {
        return;
    }
    if (!displayName || !extension) {
        saveAs(link);
        return;
    }
    const response = await fetch(link);
    const blob = await response.blob();
    saveAs(blob, `${displayName}${extension}`);
};
