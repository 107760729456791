import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

const environment = import.meta?.env?.['VITE_ENVIRONMENT'];

if (environment && environment !== 'develop') {
    Sentry.init({
        dsn: 'https://855257fda3cd714027c02d3339968191@o250244.ingest.us.sentry.io/4507526136135680',
        environment,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren: createRoutesFromChildren as any,
                matchRoutes,
            }),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [/^\//, /^\/api/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}
