import { Language } from '@shared-lib/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enCalendar from '@calendar/i18n/en.json';
import ukCalendar from '@calendar/i18n/uk.json';
import ruCalendar from '@calendar/i18n/ru.json';

export const calendarI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.CALENDAR]: enCalendar,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.CALENDAR]: ukCalendar,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.CALENDAR]: ruCalendar,
    },
};
