import { type Pagination } from '@pages/dashboard/shared/content-wrapper/pagination/pagination.types';
import { type BulkCreate } from '@pages/dashboard/shared/entity-tabs/shared/bulk.types';
import { optionalFields } from '@pages/dashboard/shared/utils/utils';
import { type createAsyncThunk, type UnknownAction } from '@reduxjs/toolkit';
import { type StateKey } from '@store/state.types';
import { createBulkThunk } from '../create.thunks';
import { deleteWithListThunk } from '../delete.thunks';
import { getListThunk } from '../get.thunks';
import { updateInPlaceThunk } from '../update.thunks';
import { type AttachmentListWithBulkApiService } from './attachment-list-with-bulk-api.service';

export abstract class AttachmentListWithBulkThunks<
    Service extends AttachmentListWithBulkApiService<List, Query, Create, Update>,
    List,
    Query,
    Create,
    Update = Partial<Create>,
> {
    public readonly bulkThunk;
    public readonly getAllThunk;
    public readonly updateInPlaceThunk;
    public readonly deleteThunk;

    public constructor(
        public readonly service: Service,
        public readonly stateKey: StateKey,
    ) {
        this.bulkThunk = createBulkThunk<BulkCreate<Create>, Query>({
            stateKey,
            serviceFunction: service.bulk.bind(service),
            thunksOnSuccess: ({ entityId, entityType }, query) => [
                this.getAllThunk({
                    ...query,
                    ...optionalFields({ entityId, entityTypes: entityType && [entityType] }),
                }),
            ],
            successMessage: `${stateKey.toUpperCase()}.CREATED`,
        });

        this.getAllThunk = getListThunk<Pagination<List>, Query>({
            stateKey,
            serviceFunction: service.getAll.bind(service),
        });

        this.updateInPlaceThunk = updateInPlaceThunk<Update, Query>({
            stateKey,
            successMessage: `${stateKey.toUpperCase()}.UPDATED`,
            serviceFunction: service.update.bind(service),
            thunksOnSuccess: this.getAllThunk,
        });

        this.deleteThunk = deleteWithListThunk({
            stateKey,
            successMessage: `${stateKey.toUpperCase()}.DELETED`,
            serviceFunction: service.delete.bind(service),
            thunksOnSuccess: this.getAllThunk,
        });
    }
    public get defaultThunks(): ReturnType<typeof createAsyncThunk<any, any, { rejectValue: UnknownAction | null }>>[] {
        return [this.bulkThunk, this.deleteThunk, this.getAllThunk, this.updateInPlaceThunk];
    }
}
