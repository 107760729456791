import { Language } from '@shared-lib/language.enum.ts';
import { createSlice, type UnknownAction } from '@reduxjs/toolkit';
import { LocalStorageKey } from '@shared/local-storage/local-storage.hook.ts';
import {
    isFulfilledAction,
    isPendingWithSkipActions,
    isRejectedAction,
    setStateValue,
} from '@shared/store/store.utils.ts';
import { type InitialBaseState } from '@store/state.types.ts';
import {
    authChangeLanguageType,
    authCheckTokenType,
    changeLanguageThunk,
    checkTokenThunk,
    loginThunk,
    logoutThunk,
    setPasswordThunk,
} from './auth.thunk';
import { type AuthClient, type AuthEmployee, type AuthResponse } from './auth.types.ts';

export type AuthState = InitialBaseState & {
    isAuthenticated: boolean;
    hasChangedDefaultPassword: boolean;
    sessionChecked: boolean;
    tokenPending: boolean;
    languagePending: boolean;
    employee?: AuthEmployee;
    client?: AuthClient;
    email?: string;
    language: Language;
};

const getDefaultLang = (): Language => (localStorage.getItem(LocalStorageKey.LANGUAGE) as Language) || Language.EN;

const initialState: AuthState = {
    tokenPending: false,
    languagePending: false,
    sessionChecked: false,
    pending: false,
    isAuthenticated: false,
    hasChangedDefaultPassword: false,
    language: getDefaultLang(),
};

const stateKey = 'auth';

export const authSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        const clearAuthState = (state: AuthState): void => {
            delete state.employee;
            delete state.client;
            delete state.email;
            state.hasChangedDefaultPassword = false;
        };

        const setAuthPayload = (
            state: AuthState,
            { employee, hasChangedDefaultPassword, client, email, language }: AuthResponse,
        ): void => {
            if (employee) {
                state.employee = employee;
            } else if (client) {
                state.client = client;
            }
            state.hasChangedDefaultPassword = hasChangedDefaultPassword;
            state.email = email;
            state.language = language;
        };

        builder
            .addCase(logoutThunk.fulfilled, (state) => {
                state.sessionChecked = true;
                state.language = getDefaultLang();
                clearAuthState(state);
            })
            .addCase(loginThunk.fulfilled, (state, { payload }) => {
                state.isAuthenticated = true;
                setAuthPayload(state, payload);
            })
            .addCase(checkTokenThunk.pending, setStateValue('tokenPending', true))
            .addCase(logoutThunk.pending, setStateValue('tokenPending', true))
            .addCase(checkTokenThunk.fulfilled, (state, { payload }) => {
                state.isAuthenticated = true;
                state.sessionChecked = true;
                setAuthPayload(state, payload);
            })
            .addCase(checkTokenThunk.rejected, (state) => {
                state.sessionChecked = true;
                clearAuthState(state);
            })
            .addCase(changeLanguageThunk.fulfilled, (state, action) => {
                state.language = action.meta.arg;
                state.languagePending = false;
            })
            .addCase(changeLanguageThunk.pending, setStateValue('languagePending', true))
            .addCase(changeLanguageThunk.rejected, setStateValue('languagePending', false))
            .addCase(setPasswordThunk.pending, setStateValue('pending', true))
            .addCase(setPasswordThunk.fulfilled, (state) => {
                state.pending = false;
                state.hasChangedDefaultPassword = true;
            })
            .addCase(setPasswordThunk.rejected, setStateValue('pending', false))
            .addMatcher(
                (action: UnknownAction) =>
                    isPendingWithSkipActions(action, stateKey, [authCheckTokenType, authChangeLanguageType]),
                (state) => {
                    state.pending = true;
                },
            )
            .addMatcher(
                (action: UnknownAction) => isFulfilledAction(action, stateKey),
                (state) => {
                    state.pending = false;
                    state.tokenPending = false;
                },
            )
            .addMatcher(
                (action: UnknownAction) => isRejectedAction(action, stateKey),
                (state) => {
                    state.pending = false;
                    state.tokenPending = false;
                },
            );
    },
});
export default authSlice.reducer;
