import Routes from '@shared/routes.enum';
import axios, { type AxiosResponse, type InternalAxiosRequestConfig } from 'axios';
import { BehaviorSubject } from 'rxjs';

export const BASE_URL = import.meta.env.PROD ? '/api' : `http://localhost:${import.meta.env['VITE_API_PORT']}`;

let abortController = new AbortController();
export const expiresIn$$ = new BehaviorSubject<number>(0);

const httpClient = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    timeout: 10000,
    signal: abortController.signal,
});

export const authHttpClient = axios.create({
    baseURL: BASE_URL,
    timeout: 10000,
    withCredentials: true,
});
export const abortRequest = (): void => {
    // TODO(xakeppok): for Strict mode
    if (import.meta.env.DEV) {
        return;
    }
    abortController.abort();
    abortController = new AbortController();
    httpClient.defaults.signal = abortController.signal;
};

const responseInterceptor = (response: AxiosResponse<any, any>): any => {
    if (!response) {
        return;
    }

    // TODO(lachesse): investigate types issue
    const expiresIn = (response.headers as any).get('expiresIn');

    if (expiresIn) {
        expiresIn$$.next(new Date().getTime() + Number(expiresIn));
    }

    return response.data;
};

const errorInterceptor = (error: { response?: { status?: number } }) => {
    if (error.response?.status === 502 && !window.location.pathname.includes(Routes.MAINTENANCE)) {
        window.location.href = Routes.MAINTENANCE;
    }
    return Promise.reject(error);
};

httpClient.interceptors.request.use((request: InternalAxiosRequestConfig) => {
    if (request?.data && request?.data instanceof FormData) {
        // TODO(lachesse): discuss timeout when sending a large number of files with a bad connection
        request.timeout = 100000;
    }
    return request;
});

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

// TODO(xakeppok): what for?
authHttpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;
