import { Language } from '@shared-lib/language.enum';
import { companyI18n } from '@company/company-i18n.ts';
import { customersI18n } from '@customers/customers-i18n.ts';
import { recruitingI18n } from '@recruiting/recruiting-i18n.ts';
import { teamI18n } from '@team/team-i18n.ts';
import { notificationsI18n } from '@pages/dashboard/pages/notifications/notifications-i18n.ts';
import { calendarI18n } from '@calendar/calendar-i18n.ts';
import { activityLogI18n } from '@activity-log/activity-log-i18n.ts';
import { headerI18n } from '@pages/dashboard/header/header-i18n.ts';
import { breadcrumbsI18n } from '@pages/dashboard/breadcrumbs/breadcrumbs-i18n.ts';
import { sessionExpirationPopupI18n } from '@pages/dashboard/session-expiration-popup/session-expiration-popup-i18n.ts';
import { authI18n } from '@auth/auth-i18n.ts';
import { sharedI18n } from '@shared/shared-i18n.ts';
import { dashboardSharedI18n } from '@dashboard-shared/dashboard-shared-i18n.ts';
import { errorBoundaryI18n } from '@pages/error-boundary/error-boundary-i18n.ts';
import { notFoundI18n } from '@pages/not-found/not-found-i18n.ts';
import { maintenanceI18n } from '@pages/maintenance/maintenance-i18n';

export const resources = {
    [Language.EN]: {
        ...companyI18n[Language.EN],
        ...customersI18n[Language.EN],
        ...recruitingI18n[Language.EN],
        ...teamI18n[Language.EN],
        ...notificationsI18n[Language.EN],
        ...calendarI18n[Language.EN],
        ...activityLogI18n[Language.EN],
        ...headerI18n[Language.EN],
        ...breadcrumbsI18n[Language.EN],
        ...sessionExpirationPopupI18n[Language.EN],
        ...authI18n[Language.EN],
        ...sharedI18n[Language.EN],
        ...dashboardSharedI18n[Language.EN],
        ...errorBoundaryI18n[Language.EN],
        ...notFoundI18n[Language.EN],
        ...maintenanceI18n[Language.EN],
    },
    [Language.UK]: {
        ...companyI18n[Language.UK],
        ...customersI18n[Language.UK],
        ...recruitingI18n[Language.UK],
        ...teamI18n[Language.UK],
        ...notificationsI18n[Language.UK],
        ...calendarI18n[Language.UK],
        ...activityLogI18n[Language.UK],
        ...headerI18n[Language.UK],
        ...breadcrumbsI18n[Language.UK],
        ...sessionExpirationPopupI18n[Language.UK],
        ...authI18n[Language.UK],
        ...sharedI18n[Language.UK],
        ...dashboardSharedI18n[Language.UK],
        ...errorBoundaryI18n[Language.UK],
        ...notFoundI18n[Language.UK],
        ...maintenanceI18n[Language.UK],
    },
    [Language.RU]: {
        ...companyI18n[Language.RU],
        ...customersI18n[Language.RU],
        ...recruitingI18n[Language.RU],
        ...teamI18n[Language.RU],
        ...notificationsI18n[Language.RU],
        ...calendarI18n[Language.RU],
        ...activityLogI18n[Language.RU],
        ...headerI18n[Language.RU],
        ...breadcrumbsI18n[Language.RU],
        ...sessionExpirationPopupI18n[Language.RU],
        ...authI18n[Language.RU],
        ...sharedI18n[Language.RU],
        ...dashboardSharedI18n[Language.RU],
        ...errorBoundaryI18n[Language.RU],
        ...notFoundI18n[Language.RU],
        ...maintenanceI18n[Language.RU],
    },
};
